import React, { useState, useEffect } from 'react';
import UpdateVehicleNft from './UpdateVehicleNft';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { injectModels } from '../../redux/injectModels';
import { useNavigate } from "react-router-dom";

const VehicleNftList = (props) => {
    const navigate = useNavigate();

  const [openToneModall, setOpenToneModall] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [details, setDetails] = useState([]);
    const [selectedNFT, setSelectedNFT] = useState(null);


  useEffect(() => {
    getAllVehicleNftList();
  }, [props.admin.loadMangeAvl]);

  const getAllVehicleNftList = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllVehicleList();
      console.log("Fetched NFTs:", res);
      if (res && res.data) {
        setDetails(res.data); // Save the list of vehicle NFTs
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error("Failed to fetch vehicle NFTs", error);
      props.application.setLoading(false);
    }
  };

  const openToonModall = (vehicle) => {
    setSelectedVehicle(vehicle); // Set the selected vehicle
    setOpenToneModall(true);
  };

  const ToneModalclose = () => {
    setOpenToneModall(false);
  };
     const handleButtonClick = (vehicle) => {
    setSelectedNFT(vehicle); // Save the selected NFT to state
    navigate("/view-vehicle-nft-list", { state: { vehicle } }); // Navigate to another page, passing the NFT as state
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>Vehicle NFT Management</h1>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={Routes.DASHBOARD}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Vehicle NFT</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <div className="data-se">
                  <div className="d-flex justify-content-between">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
                  </div>
                  <div className="tab-content pt-2" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Owner Name</th>
                            <th>VehicleName</th>                            
                            <th>Vehicle Description</th>                            
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.map((vehicle, index) => (
                            <tr key={vehicle._id}>
                              <th>{index + 1}</th>
                              <td>{vehicle.ownerName}</td>
                              <td>{vehicle.vehicleName}</td> {/* Assuming _id as model here */}
                              <td>{vehicle.vehicleDescription}</td>
                              <td>{vehicle.admin.isAdminApproved ===true ? 'Approved' : 'Pending'}</td>
                              <td>
                                <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => handleButtonClick(vehicle)}
                                  >
                                    <i className="bi bi-eye-fill"></i>
                                  </button>
{" "}
                                <button
                                   className="btn btn-primary btn-sm"
                                  onClick={() => openToonModall(vehicle)}
                                >
                                  <i className="fa-regular fa-pen-to-square"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {openToneModall && (
        <UpdateVehicleNft
          isOpen={openToneModall}
          closeModal={ToneModalclose}
          vehicle={selectedVehicle} // Pass the selected vehicle details
          props={props}
        />
      )}
    </main>
  );
};

export default injectModels(['admin', 'application'])(VehicleNftList);
