//FAQ 
export const CREATE_FAQ_SUCCESSFULLY = "CREATE_FAQ_SUCCESSFULLY";
export const CREATE_FAQ_FAILED = "CREATE_FAQ_FAILED";
export const GET_ALL_FAQ_SUCCESS = "GET_ALL_FAQ_SUCCESS";
export const GET_ALL_FAQ_FAILED = "GET_ALL_FAQ_FAILED";
export const UPDATE_FAQ_SUCCESS = "UPDATE_FAQ_SUCCESS";
export const UPDATE_FAQ_FAILED = "UPDATE_FAQ_FAILED";
export const DELETE_FAQ_SUCCESS = "DELETE_FAQ_SUCCESS";
export const DELETE_FAQ_FAILED = "DELETE_FAQ_FAILED";

//Notification manager

export const CREATE_NOTIFICATION_SUCCESSFULLY = "CREATE_NOTIFICATION_SUCCESSFULLY";
export const CREATE_NOTIFICATION_FAILED = "CREATE_NOTIFICATION_FAILED";
export const GET_ALL_NOTIFICATION_SUCCESS = "GET_ALL_NOTIFICATION_SUCCESS";
export const GET_ALL_NOTIFICATION_FAILED = "GET_ALL_NOTIFICATION_FAILED";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILED = "UPDATE_NOTIFICATION_FAILED";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";
// content management
export const GET_ALL_CONTENT_MANAGEMENT_SUCCESS = "GET_ALL_CONTENT_MANAGEMENT_SUCCESS";
export const GET_ALL_CONTENT_MANAGEMENT_FAILED = "GET_ALL_CONTENT_MANAGEMENT_FAILED";
export const UPDATE_CONTENT_MANAGEMENT_SUCCESS = "UPDATE_CONTENT_MANAGEMENT_SUCCESS";
export const UPDATE_CONTENT_MANAGEMENT_FAILED = "UPDATE_CONTENT_MANAGEMENT_FAILED";
export const DELETE_CONTENT_MANAGEMENT_SUCCESS = "DELETE_CONTENT_MANAGEMENT_SUCCESS";
export const DELETE_CONTENT_MANAGEMENT_FAILED = "DELETE_CONTENT_MANAGEMENT_FAILED";
// Contact US 
export const GET_ALL_CONTACT_US_SUCCESS = "GET_ALL_CONTACT_US_SUCCESS";
export const GET_ALL_CONTACT_US_FAILED = "GET_ALL_CONTACT_US_FAILED";
//Change password
export const PASSWORD_FAILED = "PASSWORD_FAILED";
export const PASSWORD_UPDATE = "PASSWORD_UPDATE";


export const REFRESH_FAQ_TABLE_DATA= "REFRESH_FAQ_TABLE_DATA"; 
export const REFRESH_FAQ_TABLE_DATAS= "REFRESH_FAQ_TABLE_DATAS"; 
export const REFRESH_NOTIFICATION_TABLE_DATA= "REFRESH_NOTIFICATION_TABLE_DATA"; 
export const REFRESH_NOTIFICATION_TABLE_DATAS= "REFRESH_NOTIFICATION_TABLE_DATAS"; 
export const REFRESH_CONTENT_MANAGEMENT_TABLE_DATA= "REFRESH_CONTENT_MANAGEMENT_TABLE_DATA"; 

// Users 
export const CREATE_USER_SUCCESSFULLY = "CREATE_USER_SUCCESSFULLY";
export const LOAD_USER_TABLE = "LOAD_USER_TABLE";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const GET_ALL_USER_SUCCESSFULLY = "GET_ALL_USER_SUCCESSFULLY";
export const GET_ALL_USER_FAILED = "GET_ALL_USER_FAILED";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const REFRESH_USERS_TABLE_DATA = "REFRESH_USERS_TABLE_DATA";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";

// Government panel 

export const CREATE_GOV_SUCCESSFULLY = "CREATE_GOV_SUCCESSFULLY";
export const REFRESH_GOV_TABLE_DATA = "REFRESH_GOV_TABLE_DATA";
export const CREATE_GOV_FAILED = "CREATE_GOV_FAILED";
export const GET_ALL_GOVERNMENT_SUCCESSFULLY = "GET_ALL_GOVERNMENT_SUCCESSFULLY";
export const GET_ALL_GOVERNMENT_FAILED = "GET_ALL_GOVERNMENT_FAILED";
//available nft 

export const GET_ALL_APPROVED_SUCCESS = "GET_ALL_APPROVED_SUCCESS";
export const GET_ALL_APPROVED_FAILED = "GET_ALL_APPROVED_FAILED";

export const GET_ALL_UNAPPROVED_SUCCESS = "GET_ALL_UNAPPROVED_SUCCESS";
export const GET_ALL_UNAPPROVED_FAILED = "GET_ALL_UNAPPROVED_FAILED";


export const UPDATE_AVAIL_SUCCESS = "UPDATE_AVAIL_SUCCESS";

export const REFRESH_AVAIL_TABLE_DATA = "REFRESH_AVAIL_TABLE_DATA";
export const UPDATE_AVAIL_FAILED = "UPDATE_AVAIL_FAILED";


export const GET_SINGLE_NFT_DETAILS_SUCCESS = "GET_SINGLE_NFT_DETAILS_SUCCESS";
export const GET_SINGLE_NFT_DETAILS_FAILED = "GET_SINGLE_NFT_DETAILS_FAILED";

//

export const GET_ALL_VEHICLE_NFT_SUCCESSFULLY = "GET_SINGLE_NFT_DETAILS_SUCCESS";
export const GET_ALL_VEHICLE_NFT_FAILED = "GET_SINGLE_NFT_DETAILS_FAILED";
//shared
export const GET_ALL_SHARED_NFT_SUCCESSFULLY = "GET_ALL_SHARED_NFT_SUCCESSFULLY";
export const GET_ALL_SHARED_NFT_FAILED = "GET_ALL_SHARED_NFT_FAILED";









