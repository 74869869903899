import React, { useState, useRef } from "react";
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";
import { useParams, Link} from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../constants";

function ResetPassword(props) {
  const { token } = useParams();
  const navigate = useNavigate();
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  const [successMessage, setSuccessMessage] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [npassword, setNPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '', '','']);
  const inputRefs = useRef([]);
  const email=localStorage.getItem("email");

  const togglePassword = (e) => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPassword = (e) => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  // const handleChangeOtp = (e, index) => {
  //   const value = e.target.value;
  //   if (isNaN(value)) return;
  //   const newOtp = [...otp];
  //   newOtp[index] = value;
  //   setOtp(newOtp);
  //   if (value && index < 5) {
  //     inputRefs.current[index + 1].focus();
  //   }
  // };
  const handleChangeOtp = (e, index) => {
  const value = e.target.value;
  if (isNaN(value)) return;
  const newOtp = [...otp];
  newOtp[index] = value;
  setOtp(newOtp);

  // Automatically move to the next input field
  if (value && index < otp.length - 1) {
    inputRefs.current[index + 1].focus();
  }
};

const handleFocusOtp = () => {
  // Focus on the first empty field from left to right
  const firstEmptyIndex = otp.findIndex((digit) => digit === "");
  if (firstEmptyIndex !== -1) {
    inputRefs.current[firstEmptyIndex].focus();
  }
};

const handleBackspaceOtp = (e, index) => {
  if (e.key === "Backspace" && !otp[index] && index > 0) {
    inputRefs.current[index - 1].focus();
  }
};


  const handlePasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("New Password is required");
    } else if (!passwordRegex.test(npassword)) {
      setError("New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol and minimum length is 8!");
    } else {
      setError("");
    }
    setNPassword(val.trim());
  };

  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val.trim() === "") {
      setError("Confirm Password is required!");
    } else {
      setError("");
    }
    setConfirmPassword(val.trim());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let valid = true;

    if (npassword.trim() === "") {
      setError("New Password is required!");
      valid = false;
      return;
    } else {
      if (!passwordRegex.test(npassword)) {
        setError(
          "New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol and minimum length is 8!"
        );
        return;
      }
      setError("");
    }

    if (confirmPassword.trim() === "") {
      setError("Confirm Password is required!");
      valid = false;
      return;
    } else {
      setError("");
    }

    if (npassword.length <= 8 ) {
      setError("New Password must be at least 8 characters long!");
      valid = false;
      return;
    }

    if (npassword !== confirmPassword) {
      setError("New Password and Confirm Password did not match!");
      valid = false;
      return;
    }

    const otpString = otp.join('');
    if (otpString.length !== 6) {
      setError("OTP must be 6 digits!");
      valid = false;
      return;
    }

    if (!valid) {
      toast.error("Please fill all the details!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    } else {
      setError("");
      const data = {        
        password: npassword,
        resetPasswordOtp: otpString,
      };
      const res = await props.auth.resetPassword(data);
      try {
        props.application.setLoading(true);
        if (res.success === true) {
          props.application.setLoading(false);
          toast.dismiss();
          toast.success("Password changed successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          setError("");
          navigate("/");
        } else if (res.success === false) {
          props.application.setLoading(false);
          setError("Reset Password link is invalid or has been expired!");
          setSuccessMessage("");
        }
      } catch (err) {
        props.application.setLoading(false);
        setError("Something went wrong!");
        setSuccessMessage("");
      }
    }
  };

  return (
    <React.Fragment>
      <section className="login-page section register py-3">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-8 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center pb-3">
                <Link className="logo d-flex align-items-center w-auto" to="/">
                  <img src={`/assets/img/f-logo.svg`} alt="" />
                </Link>
              </div>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="pt-1 pb-3">
                    <h5 className="card-title text-center pb-0 fs-4">
                      Reset Password
                    </h5>
                  </div>
                  <form className="row g-2 needs-validation account-login" onSubmit={handleSubmit}>
                    <div className="col-12">
                      <label htmlFor="otp" className="form-label">
                        OTP<span className="aestrick">*</span>
                      </label>
                      <div className="input-group has-validation">
                        {/* <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              value={digit}
                              onChange={(e) => handleChangeOtp(e, index)}
                              onKeyDown={(e) => handleBackspaceOtp(e, index)}
                              maxLength="1"
                              ref={(el) => (inputRefs.current[index] = el)}
                              style={{
                                width: '2rem',
                                height: '2rem',
                                textAlign: 'center',
                                marginRight: '0.5rem',
                                fontSize: '1.5rem',
                              }}
                            />
                          ))}
                        </div> */}

                        <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={handleFocusOtp}>
                          {otp.map((digit, index) => (
                            <input
                              key={index}
                              type="text"
                              value={digit}
                               onChange={(e)=> handleChangeOtp(e, index)}
                              onKeyDown={(e) => handleBackspaceOtp(e, index)}
                              maxLength="1"
                              ref={(el) => (inputRefs.current[index] = el)}
                              style={{
                                width: '2rem',
                                height: '2rem',
                                textAlign: 'center',
                                marginRight: '0.5rem',
                                fontSize: '1.5rem',
                              }}
                            />
                          ))}
                        </div>


                        
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="password" className="form-label">
                        New Password<span className="aestrick">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type={showPassword ? "text" : "password"}
                          className="form-control"
                          name="password"
                          id="password"
                          placeholder="New Password*"
                          onChange={handlePasswordChange}
                          maxLength={80}
                        />
                        <span className="btn btn-outline-secondary eye-splash" onClick={togglePassword}>
                          {showPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <label htmlFor="confirmpassword" className="form-label">
                        Confirm Password<span className="aestrick">*</span>
                      </label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend">
                          <i className="fa-solid fa-lock"></i>
                        </span>
                        <input
                          type={showNewPassword ? "text" : "password"}
                          onChange={handleConfirmPasswordChange}
                          id="confirmpassword"
                          name="confirmpassword"
                          maxLength={80}
                          className="form-control"
                          placeholder="Confirm Password*"
                        />
                        <span className="btn btn-outline-secondary eye-splash" onClick={toggleConfirmPassword}>
                          {showNewPassword ? (
                            <i className="fa-solid fa-eye"></i>
                          ) : (
                            <i className="fa-solid fa-eye-slash"></i>
                          )}
                        </span>
                      </div>
                    </div>
                    {successMessage && (
                      <div className="col-12">
                        <div className="alert alert-success">
                          {successMessage}
                        </div>
                      </div>
                    )}
                    {error && (
                      <div className="col-12">
                        <div className="alert alert-danger">{error}</div>
                      </div>
                    )}
                    <div className="col-12 login mt-3">
                      <button className="btn btn-primary w-100" type="submit" onClick={handleSubmit}>
                        Reset Password
                      </button>
                    </div>
                    <div className="col-12 text-center create-account">
                    <p className="small mb-0">
                      <Link to={Routes.DEFAULT} className="ac-new">Back to login</Link>
                    </p>
                  </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default injectModels(["admin","auth", "application"])(ResetPassword);
