import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from './constants';
import { AuthService } from '../../../Components/services';
import { JWT } from '../../../Components/shared';
import axios from "axios";
import * as CONSTANTS from "./constants";



export const login = (data) => async (dispatch) => {
  try {
    const response = await AuthService.Login(data);    
    if (response.success) {
      dispatch({ type: LOGIN_SUCCESS, payload: response.data.access_token });
      JWT.setJwt(response.data.access_token);
      return response;
    } else {
      dispatch({ type: LOGIN_FAILED, payload: null });
      return response;
    }
  } catch (error) {
    dispatch({ type: LOGIN_FAILED, payload: null });
    return Promise.reject(error);
  }
};

export const resetPassword = (data) => async (dispatch, getState) => {
  try {
    
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/reset-password`,
      data
    );
    

    if (response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.RESET_PASSWORD_UPDATE_SUCCESS, payload: response.data });
      return response.data;
    } else {
      dispatch({ type: CONSTANTS.RESET_PASSWORD_UPDATE_FAILED, payload: null });
      return { success: false };
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSTANTS.RESET_PASSWORD_UPDATE_FAILED, payload: null });
    return Promise.reject(error);
  }
};

export const logout = () => (dispatch) => {
    JWT.removeJWT();
    dispatch({ type: LOGOUT });
};