import { JWT } from '../../../Components/shared';
import { LOGOUT, LOGIN_SUCCESS, LOGIN_FAILED ,RESET_PASSWORD_UPDATE_SUCCESS ,RESET_PASSWORD_UPDATE_FAILED} from './constants';

const initialState = {
  isLoggedIn: JWT.isValidToken(),
  access_token: JWT.getJwt(),
  isUpdated :null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, isLoggedIn: true, access_token: action.payload };
    case LOGIN_FAILED:
      return { ...state, isLoggedIn: false, access_token: null };

      case RESET_PASSWORD_UPDATE_SUCCESS: {
      return {
        ...state,
        isUpdated: action.payload,
      };
    }
    case RESET_PASSWORD_UPDATE_FAILED: {
      return {
          ...state,
           access_token: null
          };
    }
    case LOGOUT:
      return { ...state, isLoggedIn: false, access_token: null };
    default:
      return state;
  }
};

export default authReducer;