
// import React, { useEffect, useState } from 'react';
// import { injectModels } from '../../redux/injectModels';
// import ViewInquiry from './ViewInquiry'
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";

// const InquiryList =(props)=> {
//     const [openToneModal, setOpenToneModal] = useState(false);
//     const [openToneModall, setOpenToneModall] = useState(false);
//     const [filteredData, setFilteredData] = useState([]);
//     const [title_id ,setTitle_Id] = useState("");
//     const [title ,setTitle] = useState("");
//     const [description , setDescription] = useState("");
//     const [contactNumber, setContactNumber] = useState("");
//     const [Company, setCompany] = useState("");
//     const [jobTitle, setJobTitle] = useState("");
//     const [message, setMessage] = useState("");

//     useEffect(() => {
//         getContactUs();
//     },[]);

//     const openToonModall = (_id ,Name,email,contactNumber,Company,jobTitle,message) => { 
//       setTitle_Id(_id)
//       setTitle(Name)
//       setDescription(email)    
//       setContactNumber(contactNumber)   
//       setCompany(Company)
//       setJobTitle(jobTitle)
//       setMessage(message)
//       setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//         setOpenToneModal(false);
//         setOpenToneModall(false);
//     };

//     const getContactUs = async () => {
//         try {
//             const res = await props.admin.getAllContactUs();
//             if (res && res.success === true) {
//                 setFilteredData(res.tickets);
//             }
//         } catch (error) {
//             console.error("Failed to fetch FAQs", error);
//         }
//     };

  
//     return (
//         <main id="main" className="main">
//             <div className="pagetitle mb-3">
//                 <h1>Inquiry Management</h1>
//                 <nav>
//                     <ol className="breadcrumb mb-0">
//                         <li className="breadcrumb-item">
//                                         <Link to={Routes.DASHBOARD}>Home</Link>

//                         </li>
//                         <li className="breadcrumb-item active">Inquiry Management</li>
//                     </ol>
//                 </nav>
//             </div>
//             <section className="section dashboard">
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="card recent-sales overflow-auto">
//                             <div className="card-body p-3">
//                                 <div className="data-se">
//                                     <div className="d-flex justify-content-between">
//                                         <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>                                        
//                                     </div>
//                                     <div className="tab-content pt-2" id="myTabContent">
//                                         <div
//                                             className="tab-pane fade show active"
//                                             id="pills-home"
//                                             role="tabpanel"
//                                             aria-labelledby="home-tab"
//                                         >
//                                             <table className="table table-bordered">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>S.No</th>
//                                                         <th>Name</th>
//                                                         <th>Email</th>
//                                                         {/* <th>Job Title</th> */}
//                                                         <th>Contact Number</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {filteredData.map((inq, index) => (
//                                                         <tr key={inq._id}>
//                                                             <td>{index + 1}</td>
//                                                             <td>{inq.Name}</td>
//                                                             <td>{inq.email}</td>
//                                                             {/* <td>{inq.jobTitle}</td> */}
//                                                             <td>{inq.contactNumber}</td>
//                                                             <td >
                                                                
//                                                                 <button 
//                                                                 className="btn btn-success btn-sm"
//                                                                 onClick={() =>
//                                                                     openToonModall(inq._id ,inq.Name,inq.email,inq.contactNumber,inq.Company,inq.jobTitle,inq.message)}>
//                                                                     <i className="bi bi-eye-fill"></i>
//                                                                 </button>                                                               
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
           
//              {openToneModall && <ViewInquiry isOpen={openToneModall} closeModal={ToneModalclose} props={props}
//               faqId={title_id}
//               name={title}
//               email={description}
//               contactNumber={contactNumber}
//               Company={Company}
//               jobTitle={jobTitle}
//               message={message}
//             />}                        
//         </main>
//     );
// }
// export default injectModels(["admin","application"])(InquiryList);

// import React, { useEffect, useState } from 'react';
// import { injectModels } from '../../redux/injectModels';
// import ViewInquiry from './ViewInquiry';
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";

// const InquiryList = (props) => {
//     const [openToneModall, setOpenToneModall] = useState(false);
//     const [filteredData, setFilteredData] = useState([]);
//     const [allData, setAllData] = useState([]); // To store all inquiries
//     const [filters, setFilters] = useState({ name: "", email: "" });
//     const [title_id, setTitle_Id] = useState("");
//     const [title, setTitle] = useState("");
//     const [description, setDescription] = useState("");
//     const [contactNumber, setContactNumber] = useState("");
//     const [Company, setCompany] = useState("");
//     const [jobTitle, setJobTitle] = useState("");
//     const [message, setMessage] = useState("");

//     useEffect(() => {
//         getContactUs();
//     }, []);

//     const openToonModall = (_id, Name, email, contactNumber, Company, jobTitle, message) => {
//         setTitle_Id(_id);
//         setTitle(Name);
//         setDescription(email);
//         setContactNumber(contactNumber);
//         setCompany(Company);
//         setJobTitle(jobTitle);
//         setMessage(message);
//         setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//         setOpenToneModall(false);
//     };

//     const getContactUs = async () => {
//         try {
//             const res = await props.admin.getAllContactUs();
//             if (res && res.success === true) {
//                 setAllData(res.tickets);
//                 setFilteredData(res.tickets);
//             }
//         } catch (error) {
//             console.error("Failed to fetch inquiries", error);
//         }
//     };

//     const handleFilterChange = (e) => {
//         const { name, value } = e.target;
//         setFilters({ ...filters, [name]: value });
//     };

//     const applyFilters = () => {
//         const filtered = allData.filter(
//             (item) =>
//                 item.Name.toLowerCase().includes(filters.name.toLowerCase()) &&
//                 item.email.toLowerCase().includes(filters.email.toLowerCase())
//         );
//         setFilteredData(filtered);
//     };

//     const resetFilters = () => {
//         setFilters({ name: "", email: "" });
//         setFilteredData(allData);
//     };

//     return (
//         <main id="main" className="main">
//             <div className="pagetitle mb-3">
//                 <h1>Inquiry Management</h1>
//                 <nav>
//                     <ol className="breadcrumb mb-0">
//                         <li className="breadcrumb-item">
//                             <Link to={Routes.DASHBOARD}>Home</Link>
//                         </li>
//                         <li className="breadcrumb-item active">Inquiry Management</li>
//                     </ol>
//                 </nav>
//             </div>
//             <section className="section dashboard">
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="card recent-sales overflow-auto">
//                             <div className="card-body p-3">
//                                 <div className="data-se">
//                                     <div className="d-flex justify-content-between mb-3">
//                                         <div>
//                                             <input
//                                                 type="text"
//                                                 name="name"
//                                                 placeholder="Filter by Name"
//                                                 className="form-control d-inline-block me-2"
//                                                 style={{ width: "400px" }}
//                                                 value={filters.name}
//                                                 onChange={handleFilterChange}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 name="email"
//                                                 placeholder="Filter by Email"
//                                                 className="form-control d-inline-block me-2"
//                                                 style={{ width: "400px" }}
//                                                 value={filters.email}
//                                                 onChange={handleFilterChange}
//                                             />
//                                             <button className="btn btn-primary me-2" onClick={applyFilters}>
//                                                 Apply Filters
//                                             </button>
//                                             <button className="btn btn-secondary" onClick={resetFilters}>
//                                                 Reset
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="tab-content pt-2" id="myTabContent">
//                                         <div
//                                             className="tab-pane fade show active"
//                                             id="pills-home"
//                                             role="tabpanel"
//                                             aria-labelledby="home-tab"
//                                         >
//                                             <table className="table table-bordered">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>S.No</th>
//                                                         <th>Name</th>
//                                                         <th>Email</th>
//                                                         <th>Contact Number</th>
//                                                         <th>Date</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
                                                
//                                                 <tbody>
//                                                     {filteredData.map((inq, index) => (
//                                                         <tr key={inq._id}>
//                                                             <td>{index + 1}</td>
//                                                             <td>{inq.Name}</td>
//                                                             <td>{inq.email}</td>
//                                                             <td>{inq.contactNumber}</td>
//                                                             <td>{inq.createdAt}</td>
//                                                             <td>
//                                                                 <button
//                                                                     className="btn btn-success btn-sm"
//                                                                     onClick={() =>
//                                                                         openToonModall(
//                                                                             inq._id,
//                                                                             inq.Name,
//                                                                             inq.email,
//                                                                             inq.contactNumber,
//                                                                             inq.Company,
//                                                                             inq.jobTitle,
//                                                                             inq.message
//                                                                         )
//                                                                     }
//                                                                 >
//                                                                     <i className="bi bi-eye-fill"></i>
//                                                                 </button>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             {openToneModall && (
//                 <ViewInquiry
//                     isOpen={openToneModall}
//                     closeModal={ToneModalclose}
//                     props={props}
//                     faqId={title_id}
//                     name={title}
//                     email={description}
//                     contactNumber={contactNumber}
//                     Company={Company}
//                     jobTitle={jobTitle}
//                     message={message}
//                 />
//             )}
//         </main>
//     );
// };

// export default injectModels(["admin", "application"])(InquiryList);


// import React, { useEffect, useState } from 'react';
// import { injectModels } from '../../redux/injectModels';
// import ViewInquiry from './ViewInquiry';
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";
// import { toast } from 'react-toastify';

// const InquiryList = (props) => {
//     const [openToneModall, setOpenToneModall] = useState(false);
//     const [filteredData, setFilteredData] = useState([]);
//     const [allData, setAllData] = useState([]); // To store all inquiries
//     const [filters, setFilters] = useState({ name: "", email: "", date: "" });
//     const [title_id, setTitle_Id] = useState("");
//     const [title, setTitle] = useState("");
//     const [description, setDescription] = useState("");
//     const [contactNumber, setContactNumber] = useState("");
//     const [Company, setCompany] = useState("");
//     const [jobTitle, setJobTitle] = useState("");
//     const [message, setMessage] = useState("");

//     useEffect(() => {
//         getContactUs();
//     }, []);

//     const openToonModall = (_id, Name, email, contactNumber, Company, jobTitle, message) => {
//         setTitle_Id(_id);
//         setTitle(Name);
//         setDescription(email);
//         setContactNumber(contactNumber);
//         setCompany(Company);
//         setJobTitle(jobTitle);
//         setMessage(message);
//         setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//         setOpenToneModall(false);
//     };

//     const getContactUs = async () => {
//         try {
//             const res = await props.admin.getAllContactUs();
//             if (res && res.success === true) {
//                 setAllData(res.tickets);
//                 setFilteredData(res.tickets);
//             }
//         } catch (error) {
//             console.error("Failed to fetch inquiries", error);
//         }
//     };

//     const handleFilterChange = (e) => {
//         const { name, value } = e.target;
//         setFilters({ ...filters, [name]: value });
//     };

//     const applyFilters = () => {
//         const today = new Date();
//         const filtered = allData.filter((item) => {
//             const createdAt = new Date(item.createdAt);
//             const selectedDate = filters.date ? new Date(filters.date) : null;

//             // Check if the selected date is not in the future
//             if (selectedDate && selectedDate > today) {
//                  toast.error("not selected the  future data", {
//                  position: toast.POSITION.TOP_CENTER,
//           });
//                 return false;
//             }

//             return (
//                 item.Name.toLowerCase().includes(filters.name.toLowerCase()) &&
//                 item.email.toLowerCase().includes(filters.email.toLowerCase()) &&
//                 (!filters.date || createdAt.toDateString() === selectedDate.toDateString())
//             );
//         });
//         setFilteredData(filtered);
//     };

//     const resetFilters = () => {
//         setFilters({ name: "", email: "", date: "" });
//         setFilteredData(allData);
//     };

//     return (
//         <main id="main" className="main">
//             <div className="pagetitle mb-3">
//                 <h1>Inquiry Management</h1>
//                 <nav>
//                     <ol className="breadcrumb mb-0">
//                         <li className="breadcrumb-item">
//                             <Link to={Routes.DASHBOARD}>Home</Link>
//                         </li>
//                         <li className="breadcrumb-item active">Inquiry Management</li>
//                     </ol>
//                 </nav>
//             </div>
//             <section className="section dashboard">
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="card recent-sales overflow-auto">
//                             <div className="card-body p-3">
//                                 <div className="data-se">
//                                     <div className="d-flex justify-content-between mb-3">
//                                         <div>
//                                             <input
//                                                 type="text"
//                                                 name="name"
//                                                 placeholder="Filter by Name"
//                                                 className="form-control d-inline-block me-2"
//                                                 style={{ width: "300px" }}
//                                                 value={filters.name}
//                                                 onChange={handleFilterChange}
//                                             />
//                                             <input
//                                                 type="text"
//                                                 name="email"
//                                                 placeholder="Filter by Email"
//                                                 className="form-control d-inline-block me-2"
//                                                 style={{ width: "300px" }}
//                                                 value={filters.email}
//                                                 onChange={handleFilterChange}
//                                             />
//                                             <input
//                                                 type="date"
//                                                 name="date"
//                                                 placeholder="Filter by Date"
//                                                 className="form-control d-inline-block me-2"
//                                                 style={{ width: "200px" }}
//                                                 value={filters.date}
//                                                 onChange={handleFilterChange}
//                                             />
//                                             <button className="btn btn-primary me-2" onClick={applyFilters}>
//                                                 Apply Filters
//                                             </button>
//                                             <button className="btn btn-secondary" onClick={resetFilters}>
//                                                 Reset
//                                             </button>
//                                         </div>
//                                     </div>
//                                     <div className="tab-content pt-2" id="myTabContent">
//                                         <div
//                                             className="tab-pane fade show active"
//                                             id="pills-home"
//                                             role="tabpanel"
//                                             aria-labelledby="home-tab"
//                                         >
//                                             <table className="table table-bordered">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>S.No</th>
//                                                         <th>Name</th>
//                                                         <th>Email</th>
//                                                         <th>Contact Number</th>
//                                                         <th>Date</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
                                                
//                                                 <tbody>
//                                                     {filteredData.map((inq, index) => (
//                                                         <tr key={inq._id}>
//                                                             <td>{index + 1}</td>
//                                                             <td>{inq.Name}</td>
//                                                             <td>{inq.email}</td>
//                                                             <td>{inq.contactNumber}</td>
//                                                             <td>{new Date(inq.createdAt).toLocaleDateString()}</td>
//                                                             <td>
//                                                                 <button
//                                                                     className="btn btn-success btn-sm"
//                                                                     onClick={() =>
//                                                                         openToonModall(
//                                                                             inq._id,
//                                                                             inq.Name,
//                                                                             inq.email,
//                                                                             inq.contactNumber,
//                                                                             inq.Company,
//                                                                             inq.jobTitle,
//                                                                             inq.message
//                                                                         )
//                                                                     }
//                                                                 >
//                                                                     <i className="bi bi-eye-fill"></i>
//                                                                 </button>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             {openToneModall && (
//                 <ViewInquiry
//                     isOpen={openToneModall}
//                     closeModal={ToneModalclose}
//                     props={props}
//                     faqId={title_id}
//                     name={title}
//                     email={description}
//                     contactNumber={contactNumber}
//                     Company={Company}
//                     jobTitle={jobTitle}
//                     message={message}
//                 />
//             )}
//         </main>
//     );
// };

// export default injectModels(["admin", "application"])(InquiryList);

import React, { useEffect, useState } from 'react';
import { injectModels } from '../../redux/injectModels';
import ViewInquiry from './ViewInquiry';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";


const InquiryList = (props) => {
    const [openToneModall, setOpenToneModall] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [allData, setAllData] = useState([]); // To store all inquiries
    const [filters, setFilters] = useState({ name: "", email: "", startDate: "", endDate: "" });
    const [title_id, setTitle_Id] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [Company, setCompany] = useState("");
    const [jobTitle, setJobTitle] = useState("");
    const [message, setMessage] = useState("");

    useEffect(() => {
        getContactUs();
    }, []);

    const openToonModall = (_id, Name, email, contactNumber, Company, jobTitle, message) => {
        setTitle_Id(_id);
        setTitle(Name);
        setDescription(email);
        setContactNumber(contactNumber);
        setCompany(Company);
        setJobTitle(jobTitle);
        setMessage(message);
        setOpenToneModall(true);
    };

    const ToneModalclose = () => {
        setOpenToneModall(false);
    };

    const getContactUs = async () => {
        try {
            const res = await props.admin.getAllContactUs();
            if (res && res.success === true) {
                setAllData(res.tickets);
                setFilteredData(res.tickets);
            }
        } catch (error) {
            console.error("Failed to fetch inquiries", error);
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const applyFilters = () => {
        const today = new Date();
        const startDate = filters.startDate ? new Date(filters.startDate) : null;
        const endDate = filters.endDate ? new Date(filters.endDate) : null;

        if (endDate && endDate > today) {
             toast.error("End date cannot be before start date.", {
            position: toast.POSITION.TOP_CENTER,
          });    
            return;
        }

        if (startDate && endDate && endDate < startDate) {
             toast.error("End date cannot be before start date.", {
            position: toast.POSITION.TOP_CENTER,
          });           
            return;
        }

        const filtered = allData.filter((item) => {
            const createdAt = new Date(item.createdAt);

            return (
                item.Name.toLowerCase().includes(filters.name.toLowerCase()) &&
                item.email.toLowerCase().includes(filters.email.toLowerCase()) &&
                (!startDate || createdAt >= startDate) &&
                (!endDate || createdAt <= endDate)
            );
        });
        setFilteredData(filtered);
    };

    const resetFilters = () => {
        setFilters({ name: "", email: "", startDate: "", endDate: "" });
        setFilteredData(allData);
    };

    return (
        <main id="main" className="main">
            <div className="pagetitle mb-3">
                <h1>Inquiry Management</h1>
                <nav>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to={Routes.DASHBOARD}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Inquiry Management</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="row">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body p-3">
                                <div className="data-se">
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <input
                                                type="text"
                                                name="name"
                                                placeholder="Filter by Name"
                                                className="form-control d-inline-block me-2"
                                                style={{ width: "300px" }}
                                                value={filters.name}
                                                onChange={handleFilterChange}
                                            />
                                            <input
                                                type="text"
                                                name="email"
                                                placeholder="Filter by Email"
                                                className="form-control d-inline-block me-2"
                                                style={{ width: "300px" }}
                                                value={filters.email}
                                                onChange={handleFilterChange}
                                            />
                                            <input
                                                type="date"
                                                name="startDate"
                                                placeholder="Start Date"
                                                className="form-control d-inline-block me-2"
                                                style={{ width: "200px" }}
                                                value={filters.startDate}
                                                onChange={handleFilterChange}
                                            />
                                            <input
                                                type="date"
                                                name="endDate"
                                                placeholder="End Date"
                                                className="form-control d-inline-block me-2"
                                                style={{ width: "200px" }}
                                                value={filters.endDate}
                                                onChange={handleFilterChange}
                                            />
                                            <button className="btn btn-primary me-2" onClick={applyFilters}>
                                                Apply Filters
                                            </button>
                                            <button className="btn btn-secondary" onClick={resetFilters}>
                                                Reset
                                            </button>
                                        </div>
                                    </div>
                                    <div className="tab-content pt-2" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Contact Number</th>
                                                        <th>Date</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                
                                                <tbody>
                                                    {filteredData.map((inq, index) => (
                                                        <tr key={inq._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{inq.Name}</td>
                                                            <td>{inq.email}</td>
                                                            <td>{inq.contactNumber}</td>
                                                            <td>{new Date(inq.createdAt).toLocaleDateString()}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() =>
                                                                        openToonModall(
                                                                            inq._id,
                                                                            inq.Name,
                                                                            inq.email,
                                                                            inq.contactNumber,
                                                                            inq.Company,
                                                                            inq.jobTitle,
                                                                            inq.message
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="bi bi-eye-fill"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {openToneModall && (
                <ViewInquiry
                    isOpen={openToneModall}
                    closeModal={ToneModalclose}
                    props={props}
                    faqId={title_id}
                    name={title}
                    email={description}
                    contactNumber={contactNumber}
                    Company={Company}
                    jobTitle={jobTitle}
                    message={message}
                />
            )}
        </main>
    );
};

export default injectModels(["admin", "application"])(InquiryList);
