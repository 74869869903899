// import React, { useState } from "react";
// import Modal from 'react-modal';
// import { injectModels } from "../../redux/injectModels";
// import { toast } from "react-toastify";

// const UpdateUser = ({isOpen, closeModal,props,_id, firstName,lastName,email,isActive}) => {

//   const [userId, setUserId] = useState(_id);
//   const [name, setName] = useState(firstName);
//   const [lastNames,setLastNames] =useState(lastName)
//   const [lastNamesError,setLastNamesError] =useState("")
//   const [emails, setEmails] = useState(email);
//   const [nameError, setNameError] = useState("");
//   const [emailsError, setEmailsError] = useState("");
//   const [error, setError] = useState("");  
//   const [status , setStatus] = useState(isActive)

//   const handleFirstName = (e) => {
//     const val = e.target.value;
//     if (val === "") {
//       setNameError("First name is required!");
//     } else {
//       setNameError("");
//     }
//     setName(val.trim());
//   };

//  const handleLastName = (e) => {
//     const val = e.target.value;
//     if (val === "") {
//       setLastNamesError("Last name is required!");
//     } else {
//       setLastNamesError("");
//     }
//     setLastNames(val.trim());
//   };

 


//   const handleSubmit = async(e) => {
//     e.preventDefault();
//     let error = true;
//     if(name.trim() === "") {
//       error = false;
//       setNameError("Name is required!");
//     } else {
//       setNameError("");
//     }
//      if(lastNames.trim() === "") {
//       error = false;
//       setLastNamesError("Last name is required!");
//     } else {
//       setLastNamesError("");
//     }

   
   

//       if (!error) {
//         setError("Please fill all fields!");
//         return;
//       } else {
//         setError("");
    
//         try {
//           let data = {
//             firstName:name,
//             lastName:lastNames,
//             email:emails,
//           }
//           props.application.setLoading(true);
//           const res = await props.admin.createUser(data);    
//           if (res.success === true) {
//             props.application.setLoading(false);
//             toast.success(res.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//             handleResetForm();
//           } else {
//             toast.error(res.data.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//             props.application.setLoading(false);
//           }
//         } catch (error) {
//           console.log(error);
//           props.application.setLoading(false);
//           toast.error("Something went wrong!", {
//             position: toast.POSITION.TOP_CENTER,
//           });
//         }
//       }
//     };    
    
// const handleResetForm =()=>{
//     setName("");
//       setLastNames("")
//       setEmails("");
// }

// const handlReset = () => {
//     closeModal();   
//        setNameError("");
//       setLastNamesError("");
//       setEmailsError("");
//   }
  
// const CloseModal = () => {
//     closeModal();
//   };

//   return (
//     <React.Fragment>
//       <div>
//         <Modal
//           isOpen={isOpen}
//           onRequestClose={CloseModal}
//           contentLabel="Example Modal"
//           style={{
//             content: {
//               top: '50%',
//               left: '50%',
//               right: 'auto',
//               bottom: 'auto',
//               marginRight: '-50%',
//               transform: 'translate(-50%, -50%)',
//               overflowY: 'auto',

//             },
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.5)',
//               zIndex: 1000,
//             },
//           }}
//         >
//           <section className="setting-popup">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <div className="modal-header-profile">
//                   <div className="user-profile-use">                   
//                     <h6>Update Users Details</h6>
//                   </div>
//                   <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
//                 </div>
//               </div>
//               <div className="modal-body">
//                 <div className="user-details-form">
//               <form className="row g-3 p-2" onSubmit={handleSubmit}>
//               <div className="col-md-6">
//                 <label htmlFor="" className="form-label">
//                   First Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={name}
//                   onChange={handleFirstName}
//                 />
//                 {nameError && (
//                   <div className="text-danger">{nameError}</div>
//                 )}
//               </div>
//                 <div className="col-md-6">
//                 <label htmlFor="" className="form-label">
//                   Last Name
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"
//                   value={lastNames}
//                   onChange={handleLastName}
//                 />
//                 {lastNamesError && (
//                   <div className="text-danger">{lastNamesError}</div>
//                 )}
//               </div>
//               <div className="col-md-6">
//                 <label htmlFor="" className="form-label">
//                   Email
//                 </label>
//                 <input
//                   type="email"
//                   className="form-control"
//                   value={email}                
//                   readOnly
//                 />               
//               </div>
             
//               <div className="mbtn-row">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"
//                   onClick={handlReset}>
//                   Reset
//                 </button>
//                 <button type="submit" className="btn btn-primary">
//                   Save changes
//                 </button>
//               </div>
//             </form>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </Modal>
//       </div>
//     </React.Fragment>
//   );
// };
// export default injectModels(["admin","application"])(UpdateUser);


import React, { useState } from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";

const UpdateUser = ({ isOpen, closeModal, props, _id, firstName, lastName, email, isActive }) => {
  const [userId, setUserId] = useState(_id);
  const [name, setName] = useState(firstName);
  const [lastNames, setLastNames] = useState(lastName);
  const [lastNamesError, setLastNamesError] = useState("");
  const [emails, setEmails] = useState(email);
  const [nameError, setNameError] = useState("");
  const [emailsError, setEmailsError] = useState("");
  const [error, setError] = useState("");
  const [status, setStatus] = useState(isActive ? "Enabled" : "Disabled");

  const handleFirstName = (e) => {
    const val = e.target.value;
    if (val === "") {
      setNameError("First name is required!");
    } else {
      setNameError("");
    }
    setName(val.trim());
  };

  const handleLastName = (e) => {
    const val = e.target.value;
    if (val === "") {
      setLastNamesError("Last name is required!");
    } else {
      setLastNamesError("");
    }
    setLastNames(val.trim());
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = true;
    if (name.trim() === "") {
      error = false;
      setNameError("Name is required!");
    } else {
      setNameError("");
    }
    if (lastNames.trim() === "") {
      error = false;
      setLastNamesError("Last name is required!");
    } else {
      setLastNamesError("");
    }

    if (!error) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");

      try {
        let data = {
          id:userId,
          firstName: name,
          lastName :lastNames,
          isActive: status,
        };
        props.application.setLoading(true);
        const res = await props.admin.updateUser(data);        
        if (res.data.success === true) {
          CloseModal()
          props.application.setLoading(false);
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          handleResetForm();
          handleReset();
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        props.application.setLoading(false);
        toast.error("Something went wrong!", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleResetForm = () => {
    setName("");
    setLastNames("");
    setEmails("");
    setStatus("");
  };

  const handleReset = () => {
    closeModal();
    setNameError("");
    setLastNamesError("");
    setEmailsError("");
  };

  const CloseModal = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">
                    <h6>Update Users Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
                  <form className="row g-3 p-2" onSubmit={handleSubmit}>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">First Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={name}
                        onChange={handleFirstName}
                      />
                      {nameError && (
                        <div className="text-danger">{nameError}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">Last Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={lastNames}
                        onChange={handleLastName}
                        
                      />
                      {lastNamesError && (
                        <div className="text-danger">{lastNamesError}</div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        readOnly
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="form-label">Status</label>
                      <select
                        className="form-control"
                        value={status}
                        onChange={handleStatusChange}
                      >
                        <option value="Enabled">Enabled</option>
                        <option value="Disabled">Disabled</option>
                      </select>
                    </div>
                    <div className="mbtn-row">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleReset}>
                        Reset
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default injectModels(["admin", "application"])(UpdateUser);
