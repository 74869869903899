// import React, { useEffect, useState } from 'react';
// import CreateNotification from './CreateNotification';
// import { injectModels } from '../../redux/injectModels';
// import UpdateNotification from './UpdateNotification';
// import Swal from "sweetalert2";
// import { toast } from "react-toastify";
// import ViewNotification from './ViewNotification';
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";

// const NotificationList =(props)=> {
//     const [openToneModal, setOpenToneModal] = useState(false);
//     const [openToneModall, setOpenToneModall] = useState(false);
//     const [filteredData, setFilteredData] = useState([]);
//     const [title_id ,setTitle_Id] = useState("");
//     const [title ,setTitle] = useState("");
//     const [description , setDescription] = useState("");

//     useEffect(() => {
//         getAllNotificationList();
//     },[props.admin.loadNotificationTableData,props.admin.loadNotificationTableDatas]);

//     const openToonModal = (_id ,name,description) => { 
//       setTitle_Id(_id)
//       setTitle(name)
//       setDescription(description)       
//       setOpenToneModal(true);
//     };

//     const openToonModall = (_id ,name,description) => { 
//       setTitle_Id(_id)
//       setTitle(name)
//       setDescription(description)       
//       setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//         setOpenToneModal(false);
//         setOpenToneModall(false);
//     };

//     const getAllNotificationList = async () => {
//     props.application.setLoading(true);
//     try {
//         const res = await props.admin.getAllNotification();        
//         if (res && res.obj && Array.isArray(res.obj.notifications)) {
//             setFilteredData(res.obj.notifications);
//         } else {
//             console.error("Invalid response format or no notifications found");
//             setFilteredData([]); 
//         }
//         props.application.setLoading(false);
//     } catch (error) {
//         console.error("Failed to fetch notifications", error);
//         props.application.setLoading(false);
//     }
// };

//   const deleteNotification = async (id) => {
//       let data = {
//         id:id
//       }
//     Swal.fire({
//       title: "Confirmation",
//       text: "Are you sure you want to delete?",
//       showCancelButton: true,
//       confirmButtonColor: "#009462",
//       cancelButtonColor: "#314a5f",
//       confirmButtonText: `Yes`,
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           props.application.setLoading(true);
//           const res = await props.admin.deleteNotification(data);          
//           if (res.success === true) {
//             toast.dismiss();
//             toast.success(res.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//             getAllNotificationList();
//             props.application.setLoading(false);
//           } else {
//             props.application.setLoading(false);
//             toast.dismiss();
//             toast.success(res.message, {
//               position: toast.POSITION.TOP_CENTER,
//             });
//           }
//         } catch (error) {
//           console.log(error);
//           props.application.setLoading(false);
//           toast.dismiss();
//           toast.success("Something went wrong!", {
//             position: toast.POSITION.TOP_CENTER,
//           });
//         }
//       }
//     });
//   };
//   const truncateText = (text, wordLimit) => {
//   if (!text) return ""; // Handle cases where the text might be undefined or null
//   const words = text.split(" ");
//   return words.length > wordLimit
//     ? words.slice(0, wordLimit).join(" ") + "..."
//     : text;
// };

//     return (
//         <main id="main" className="main">
//             <div className="pagetitle mb-3">
//                 <h1>Notification Management</h1>
//                 <nav>
//                     <ol className="breadcrumb mb-0">
//                         <li className="breadcrumb-item">
//                                         <Link to={Routes.DASHBOARD}>Home</Link>

//                         </li>
//                         <li className="breadcrumb-item active">Notification Management</li>
//                     </ol>
//                 </nav>
//             </div>
//             <section className="section dashboard">
//                 <div className="row">
//                     <div className="col-12">
//                         <div className="card recent-sales overflow-auto">
//                             <div className="card-body p-3">
//                                 <div className="data-se">
//                                     <div className="d-flex justify-content-between">
//                                         <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
//                                         <div>
//                                             <a
//                                                 href="#"
//                                                 className="btn btn-primary"
//                                                 data-bs-toggle="modal"
//                                                 data-bs-target="#largeModal">
//                                                 Create Notification
//                                             </a>
//                                         </div>
//                                     </div>
//                                     <div className="tab-content pt-2" id="myTabContent">
//                                         <div
//                                             className="tab-pane fade show active"
//                                             id="pills-home"
//                                             role="tabpanel"
//                                             aria-labelledby="home-tab"
//                                         >
//                                             <table className="table table-bordered">
//                                                 <thead>
//                                                     <tr>
//                                                         <th>S.No</th>
//                                                         <th>Title</th>
//                                                         <th>Description</th>
//                                                         <th>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {filteredData.map((faq, index) => (
//                                                         <tr key={faq._id}>
//                                                             <td>{index + 1}</td>
//                                                             <td>{faq.name}</td>
//                                                             <td>{truncateText(faq.description , 20 )}</td>
//                                                             <td >
//                                                                  <button
//                                                                     className="btn btn-danger btn-sm"                                                                    
//                                                                     onClick={() => deleteNotification(faq._id)}
//                                                                   >
//                                                                     <i className="bi bi-trash-fill"></i>
//                                                                   </button>
//                                                                 &nbsp;
//                                                                 <button 
//                                                                 className="btn btn-success btn-sm"
//                                                                 onClick={() =>
//                                                                     openToonModall(faq._id ,faq.name,faq.description)}>
//                                                                     <i className="bi bi-eye-fill"></i>
//                                                                 </button>
//                                                                 &nbsp;
//                                                                 <button 
//                                                                   className="btn btn-primary btn-sm"                                                                
//                                                                 onClick={() =>
//                                                                     openToonModal(faq._id ,faq.name,faq.description)}>
//                                                                     <i className="fa-regular fa-pen-to-square"></i>
//                                                                 </button>
//                                                                 &nbsp;
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//             <CreateNotification />
//             {openToneModal && <UpdateNotification isOpen={openToneModal} closeModal={ToneModalclose} props={props}
//               faqId={title_id}
//               title={title}
//               description={description}
//             />} 
//              {openToneModall && <ViewNotification isOpen={openToneModall} closeModal={ToneModalclose} props={props}
//               faqId={title_id}
//               title={title}
//               description={description}
//             />}                        
//         </main>
//     );
// }
// export default injectModels(["admin","application"])(NotificationList);


import React, { useEffect, useState } from 'react';
import CreateNotification from './CreateNotification';
import { injectModels } from '../../redux/injectModels';
import UpdateNotification from './UpdateNotification';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import ViewNotification from './ViewNotification';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";

const NotificationList = (props) => {
    const [openToneModal, setOpenToneModal] = useState(false);
    const [openToneModall, setOpenToneModall] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [notifications, setNotifications] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [title_id, setTitle_Id] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        getAllNotificationList();
    }, [props.admin.loadNotificationTableData, props.admin.loadNotificationTableDatas]);

    const openToonModal = (_id, name, description) => { 
        setTitle_Id(_id);
        setTitle(name);
        setDescription(description);       
        setOpenToneModal(true);
    };

    const openToonModall = (_id, name, description) => { 
        setTitle_Id(_id);
        setTitle(name);
        setDescription(description);       
        setOpenToneModall(true);
    };

    const ToneModalclose = () => {
        setOpenToneModal(false);
        setOpenToneModall(false);
    };

    const getAllNotificationList = async () => {
        props.application.setLoading(true);
        try {
            const res = await props.admin.getAllNotification();        
            if (res && res.obj && Array.isArray(res.obj.notifications)) {
                setNotifications(res.obj.notifications);
                setFilteredData(res.obj.notifications);
            } else {
                console.error("Invalid response format or no notifications found");
                setNotifications([]);
                setFilteredData([]);
            }
            props.application.setLoading(false);
        } catch (error) {
            console.error("Failed to fetch notifications", error);
            props.application.setLoading(false);
        }
    };

    const deleteNotification = async (id) => {
        let data = { id: id };
        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonColor: "#009462",
            cancelButtonColor: "#314a5f",
            confirmButtonText: `Yes`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    props.application.setLoading(true);
                    const res = await props.admin.deleteNotification(data);          
                    if (res.success === true) {
                        toast.dismiss();
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        getAllNotificationList();
                        props.application.setLoading(false);
                    } else {
                        props.application.setLoading(false);
                        toast.dismiss();
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } catch (error) {
                    console.log(error);
                    props.application.setLoading(false);
                    toast.dismiss();
                    toast.success("Something went wrong!", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        });
    };

    const truncateText = (text, wordLimit) => {
        if (!text) return ""; // Handle cases where the text might be undefined or null
        const words = text.split(" ");
        return words.length > wordLimit
            ? words.slice(0, wordLimit).join(" ") + "..."
            : text;
    };

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        const filtered = notifications.filter(notification =>
            notification.name.toLowerCase().includes(query)
        );
        setFilteredData(filtered);
    };

    return (
        <main id="main" className="main">
            <div className="pagetitle mb-3">
                <h1>Notification Management</h1>
                <nav>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to={Routes.DASHBOARD}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Notification Management</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="row">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body p-3">
                                <div className="data-se">
                                    <div className="d-flex justify-content-between">
                                        <input
                                            type="text"
                                            className="form-control w-50"
                                            placeholder="Search by Title..."
                                            value={searchQuery}
                                            onChange={handleSearch}
                                        />
                                        <div>
                                            <a
                                                href="#"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#largeModal">
                                                Create Notification
                                            </a>
                                        </div>
                                    </div>
                                    <div className="tab-content pt-2" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((faq, index) => (
                                                        <tr key={faq._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{faq.name}</td>
                                                            <td>{truncateText(faq.description, 20)}</td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => deleteNotification(faq._id)}
                                                                >
                                                                    <i className="bi bi-trash-fill"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() =>
                                                                        openToonModall(faq._id, faq.name, faq.description)
                                                                    }>
                                                                    <i className="bi bi-eye-fill"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() =>
                                                                        openToonModal(faq._id, faq.name, faq.description)
                                                                    }>
                                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                                </button>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CreateNotification />
            {openToneModal && <UpdateNotification isOpen={openToneModal} closeModal={ToneModalclose} props={props}
                faqId={title_id}
                title={title}
                description={description}
            />} 
            {openToneModall && <ViewNotification isOpen={openToneModall} closeModal={ToneModalclose} props={props}
                faqId={title_id}
                title={title}
                description={description}
            />}                        
        </main>
    );
};

export default injectModels(["admin", "application"])(NotificationList);
