import React, { useState } from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";

const UpdateNotification = ({isOpen, closeModal,props,faqId,title,description}) => {
const [titleId,setTitleId]=useState(faqId);
const [titles,setTitles]=useState(title);
const [titleError,setTitleError]=useState("");
const [descriptions,setDescriptions] = useState(description);
const [descriptionError,setDescriptionError] = useState();

  const handleTitle = (e) => {
    const val = e.target.value;
    if (val === "") {
      setTitleError("Title is required!");
    } else {
      setTitleError("");
    }
    setTitles(val);
  };

  const handleDescription= (e) => {
    const val = e.target.value;
    if (val === "") {
      setDescriptionError("Description is required!");
    } else {
      setDescriptionError("");
    }
    setDescriptions(val);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (titles == "" || titles == null) {
      valid = false;
      setTitleError('Title is required');
    } else {
      setTitleError("");
    }

    if (descriptions == "" || descriptions == null) {
      valid = false;
      setDescriptionError('Description is required');
    } else {
      setDescriptionError("");
    }

    if (!valid) {
      return;
    }
    else {
      const data = {
        id: titleId,
        name: titles,
        description: descriptions,
      }
      try {
        const updatedResponse = await props.admin.updateNotification(data);        
        if (updatedResponse && updatedResponse.data.success === true) {          
          closeModal();
          toast.success(`${updatedResponse.data.message}`, {
            position: "top-center",
            className: "custom-toast",
          });
        } else {
          toast.error("Something went wrong.", {
            position: "top-center",            
          className: 'custom-toast-error',
          });
        }
      } catch (error) {
        console.log("error", error)
        console.error("Error updating notification details:", error);
      }
    }
  }
  const handlReset = () => {
    closeModal();   
  }
  
  const CloseModal = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',

            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">                   
                    <h6>Update Notification Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
                <form className="row g-3 p-2">
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"    
                  value={titles} 
                  onChange={handleTitle}                           
                />  
                 {titleError && (
                  <div className="text-danger">{titleError}</div>
                )}  
                            
              </div> 
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control" 
                  value={descriptions}
                  onChange={handleDescription}
                />   
                    {descriptionError && (
                  <div className="text-danger">{descriptionError}</div>
                )}        
              </div>     
                <div className="mbtn-row">
                <button
                  type="button"
                  className="btn btn-secondary"                  
                  onClick={handlReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                  Save changes
                </button>
              </div>
            </form>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default injectModels(["admin","application"])(UpdateNotification);