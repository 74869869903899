import React from 'react';
import { JWT } from '../Components/shared';
import {Routes} from "../constants";
import Login from "../Components/loginFiles/Login";
import {Navigate } from 'react-router-dom';

const PublicRoutes = () => {
    const isValidToken = JWT.isValidToken();  
    return (
      <React.Fragment>
        {isValidToken ? <Navigate to={Routes.DASHBOARD} /> : <Login />}
      </React.Fragment>
    );
};
export default PublicRoutes;
 