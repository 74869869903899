// import React, { useState } from "react";
// import Modal from 'react-modal';
// import { injectModels } from "../../redux/injectModels";
// import { toast } from "react-toastify";

// const UpdateFAQ = ({isOpen, closeModal,props,faqId,title,description}) => {
// const [titleId,setTitleId]=useState(faqId);
// const [titles,setTitles]=useState(title);
// const [titlesError,setTitlesError]=useState("");
// const [descriptions,setDescriptions] = useState(description);
// const [descriptionsError,setDescriptionsError] = useState();

//   const handleTitle = (e) => {
//     const val = e.target.value;
//     if (val == "") {
//       setTitlesError("Title is required!");
//     } else {
//       setTitlesError("");
//     }
//     setTitles(val.trimStart());
//   };

//   const handleDescription= (e) => {
//     const val = e.target.value;
//     if (val == "") {
//       setDescriptionsError("Description is required!");
//     } else {
//       setDescriptionsError("");
//     }
//     setDescriptions(val.trimStart());
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     let valid = true;

//     if (titles == "" || titles == null) {
//       valid = false;
//       setTitlesError('Title is required');
//     } else {
//       setTitlesError("");
//     }

//     if (descriptions == "" || descriptions == null) {
//       valid = false;
//       setDescriptionsError('Description is required');
//     } else {
//       setDescriptionsError("");
//     }

//     if (!valid) {
//       return;
//     }
//     else {
//       const data = {
//         id: titleId,
//         question : titles,
//         answer: descriptions,
//       }
//       try {
//         const updatedResponse = await props.admin.updateFaq(data);       
//         if (updatedResponse && updatedResponse.data.success === true) {          
//           closeModal();
//           toast.success("Faq has been updated successfully.", {
//             position: "top-center",
//             className: "custom-toast",
//           });
//         } else {
//           toast.error("Something went wrong.", {
//             position: "top-center",            
//           className: 'custom-toast-error',
//           });
//         }
//       } catch (error) {
//         console.log("error", error)
//         console.error("Error updating Faq details:", error);
//       }
//     }
//   }
//   const handlReset = () => {
//     closeModal();   
//   }
  
//   const CloseModal = () => {
//     closeModal();
//   };

//   return (
//     <React.Fragment>
//       <div>
//         <Modal
//           isOpen={isOpen}
//           onRequestClose={CloseModal}
//           contentLabel="Example Modal"
//           style={{
//             content: {
//               top: '50%',
//               left: '50%',
//               right: 'auto',
//               bottom: 'auto',
//               marginRight: '-50%',
//               transform: 'translate(-50%, -50%)',
//               overflowY: 'auto',

//             },
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.5)',
//               zIndex: 1000,
//             },
//           }}
//         >
//           <section className="setting-popup">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <div className="modal-header-profile">
//                   <div className="user-profile-use">                   
//                     <h6>Update FAQ Details</h6>
//                   </div>
//                   <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
//                 </div>
//               </div>
//               <div className="modal-body">
//                 <div className="user-details-form">
//                 <form className="row g-3 p-2">
//               <div className="col-md-12">
//                 <label htmlFor="" className="form-label">
//                   Title
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"    
//                   value={titles} 
//                   onChange={handleTitle}                           
//                 />  
//                  {titlesError && (
//                   <div className="text-danger">{titlesError}</div>
//                 )}  
                            
//               </div> 
//               <div className="col-md-12">
//                 <label htmlFor="" className="form-label">
//                   Description
//                 </label>
//                 <textarea
//                   type="text"
//                   className="form-control" 
//                   value={descriptions}
//                   onChange={handleDescription}
//                 />   
//                     {descriptionsError && (
//                   <div className="text-danger">{descriptionsError}</div>
//                 )}        
//               </div>     
//                 <div className="mbtn-row">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"                  
//                   onClick={handlReset}
//                 >
//                   Reset
//                 </button>
//                 <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
//                   Save changes
//                 </button>
//               </div>
//             </form>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </Modal>
//       </div>
//     </React.Fragment>
//   );
// };
// export default injectModels(["admin","application"])(UpdateFAQ);

import React, { useState } from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles

const UpdateFAQ = ({isOpen, closeModal, props, faqId, title, description}) => {
  const [titleId, setTitleId] = useState(faqId);
  const [titles, setTitles] = useState(title);
  const [titlesError, setTitlesError] = useState("");
  const [descriptions, setDescriptions] = useState(description);
  const [descriptionsError, setDescriptionsError] = useState("");

  const handleTitle = (e) => {
    const val = e.target.value;
    if (val === "") {
      setTitlesError("Title is required!");
    } else {
      setTitlesError("");
    }
    setTitles(val.trimStart());
  };

    const handleDescription = (value) => {
    const doc = new DOMParser().parseFromString(value, 'text/html');
    const cleanedValue = doc.body.textContent || "";

    setDescriptions(value);
    if (!cleanedValue.trim()) {
      setDescriptionsError("Description is required!");
    } else {
      setDescriptionsError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;

    if (titles === "" || titles == null) {
      valid = false;
      setTitlesError('Title is required');
    } else {
      setTitlesError("");
    }

    if (descriptions === "" || descriptions == null) {
      valid = false;
      setDescriptionsError('Description is required');
    } else {
      setDescriptionsError("");
    }

    if (!valid) {
      return;
    } else {
      const data = {
        id: titleId,
        question: titles,
        answer: descriptions,
      };
      try {
        const updatedResponse = await props.admin.updateFaq(data);
        if (updatedResponse && updatedResponse.data.success === true) {
          closeModal();
          toast.success("FAQ has been updated successfully.", {
            position: "top-center",
            className: "custom-toast",
          });
        } else {
          toast.error("Something went wrong.", {
            position: "top-center",
            className: 'custom-toast-error',
          });
        }
      } catch (error) {
        console.log("error", error);
        console.error("Error updating FAQ details:", error);
      }
    }
  };

  const handleReset = () => {
    closeModal();
  };

  const CloseModal = () => {
    closeModal();
  };
  

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Update FAQ Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">
                    <h6>Update FAQ Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}>
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
                  <form className="row g-3 p-2">
                    <div className="col-md-12">
                      <label htmlFor="" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={titles}
                        onChange={handleTitle}
                      />
                      {titlesError && (
                        <div className="text-danger">{titlesError}</div>
                      )}
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="" className="form-label">
                        Description
                      </label>
                      <ReactQuill
                        value={descriptions}
                        onChange={handleDescription}
                        className="form-control"
                        theme="snow" // You can choose 'bubble' or another theme as needed
                      />
                      {descriptionsError && (
                        <div className="text-danger">{descriptionsError}</div>
                      )}
                    </div>
                    <div className="mbtn-row">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(UpdateFAQ);
