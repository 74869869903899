import React from "react";
import { injectModels } from "../redux/injectModels";
import { Routes } from "../constants";
import { Link } from "react-router-dom";

const Dashboard = () => {  
  return (
   <>
   <main id="main" class="main">
    <div class="pagetitle mb-3">
      <h1>Dashboard</h1>
      <nav>
        <ol class="breadcrumb mb-0">
          <li class="breadcrumb-item"><Link to={Routes.DASHBOARD}>Home</Link></li>
          <li class="breadcrumb-item active">Dashboard</li>
        </ol>
      </nav>
    </div>
    <section class="section dashboard">
      <div class="row">        
        <div class="col-lg-12">
          <div class="row">            
            <div class="col-xxl-4 col-md-6">
              <div class="card info-card sales-card">
                <div class="card-body">
                  <h5 class="card-title"><strong class=" small">Total Users</strong></h5>
                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-amd"></i>
                    </div>
                    <div class="ps-3">
                      <h6>15</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
            <div class="col-xxl-4 col-md-6">
              <div class="card info-card revenue-card">
                <div class="card-body">
                  <h5 class="card-title"><strong class=" small">Total NFT </strong></h5>
                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-award-fill"></i>
                    </div>
                    <div class="ps-3">
                      <h6>20</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-12">
              <div class="card info-card customers-card">        
                <div class="card-body">
                  <h5 class="card-title"><strong class=" small">Total CAR NFT</strong></h5>
                  <div class="d-flex align-items-center">
                    <div class="card-icon rounded-circle d-flex align-items-center justify-content-center">
                      <i class="bi bi-people"></i>
                    </div>
                    <div class="ps-3">
                      <h6>144</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
   </>
  );
}
export default injectModels(["admin","application"])(Dashboard);
