// import React, { useState } from "react";
// import Modal from 'react-modal';
// import { injectModels } from "../../redux/injectModels";
// import { toast } from "react-toastify";

// const UpdateAvailableNft = ({ isOpen, closeModal, props, nftDetails }) => {
//   // const [isAdminNftApproved, setIsAdminNftApproved] = useState(nftDetails?.isAdminApproved ?? false);
//   const [isAdminNftApproved, setIsAdminNftApproved] = useState(nftDetails?.isAdminApproved ?? null);
//   const [isAdminNftApprovedId, setIsAdminNftApprovedId] = useState(nftDetails._id);

//   const CloseModal = () => {
//     closeModal();
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     if (isAdminNftApproved === null) {
//       toast.error("Please select an approval status.", {
//         position: "top-center",
//         className: 'custom-toast-error',
//       });
//       return;
//     }
//     const data = {
//       isAdminApproved: isAdminNftApproved,
//     };
    
//     try {
//       const endId = isAdminNftApprovedId;
//       const updatedResponse = await props.admin.updateAvailableNft(endId, data);      
//       if (updatedResponse && updatedResponse.status === 200) {
//         toast.success("NFT status has been updated successfully.", {
//           position: "top-center",
//           className: "custom-toast",
//         });
//         closeModal();
//       } else {
//         toast.error("Something went wrong.", {
//           position: "top-center",
//           className: 'custom-toast-error',
//         });
//       }
//     } catch (error) {
//       console.error("Error updating NFT details:", error);
//     }
//   };

//   const handleChange = (event) => {
//     const value = event.target.value === "true"; 
//     setIsAdminNftApproved(value);
//   };

//   const handleReset = () => {
//     closeModal();
//   };

//   return (
//     <React.Fragment>
//       <Modal
//         isOpen={isOpen}
//         onRequestClose={CloseModal}
//         contentLabel="Update NFT Modal"
//         style={{
//           content: {
//             top: '50%',
//             left: '50%',
//             right: 'auto',
//             bottom: 'auto',
//             marginRight: '-50%',
//             transform: 'translate(-50%, -50%)',
//             overflowY: 'auto',
//           },
//           overlay: {
//             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//             zIndex: 1000,
//           },
//         }}
//       >
//         <section className="setting-popup">
//           <div className="modal-content">
//             <div className="modal-header">
//               <div className="modal-header-profile">
//                 <div className="user-profile-use">
//                   <h6>Update NFT Status</h6>
//                 </div>
//                 <button type="button" className="btn-close" onClick={CloseModal}>
//                   <i className="fa-solid fa-xmark"></i>
//                 </button>
//               </div>
//             </div>
//             <div className="modal-body">
//               <div className="user-details-form">
//                 <form className="row g-3 p-2" onSubmit={handleSubmit}>
//                   <div className="col-md-12">
//                     <label htmlFor="nftApproval" className="form-label">
//                       Approve NFT
//                     </label>
//                     <div id="nftApproval">
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="nftApproval"
//                           id="approveTrue"
//                           value="true"
//                           checked={isAdminNftApproved === true}
//                           onChange={handleChange}
//                         />
//                         <label className="form-check-label" htmlFor="approveTrue">
//                           True
//                         </label>
//                       </div>
//                       <div className="form-check">
//                         <input
//                           className="form-check-input"
//                           type="radio"
//                           name="nftApproval"
//                           id="approveFalse"
//                           value="false"
//                           checked={isAdminNftApproved === false}
//                           onChange={handleChange}
//                         />
//                         <label className="form-check-label" htmlFor="approveFalse">
//                           False
//                         </label>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="mbtn-row">
//                     <button
//                       type="button"
//                       className="btn btn-secondary"
//                       onClick={handleReset}
//                     >
//                       Reset
//                     </button>
//                     <button type="submit" className="btn btn-primary">
//                       Save changes
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </section>
//       </Modal>
//     </React.Fragment>
//   );
// };

// export default injectModels(["admin", "application"])(UpdateAvailableNft);

import React, { useState } from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";

const UpdateAvailableNft = ({ isOpen, closeModal, props, nftDetails }) => {
  const [isAdminNftApproved, setIsAdminNftApproved] = useState(nftDetails?.isAdminApproved ?? null);
  const [isAdminNftApprovedId, setIsAdminNftApprovedId] = useState(nftDetails._id);
  const [description, setDescription] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const CloseModal = () => {
    closeModal();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isAdminNftApproved === null) {
      toast.error("Please select an approval status.", {
        position: "top-center",
        className: 'custom-toast-error',
      });
      return;
    }

    if (isAdminNftApproved === false && !description) {
      setDescriptionError("Description is required when approval is 'False'.");
      return;
    }

    const data = {
      isAdminApproved: isAdminNftApproved,
      declineReason: description, // Send the description if approval is 'False'
    };
    
    try {
      const endId = isAdminNftApprovedId;
      const updatedResponse = await props.admin.updateAvailableNft(endId, data);      
      if (updatedResponse && updatedResponse.status === 200) {
        toast.success("NFT status has been updated successfully.", {
          position: "top-center",
          className: "custom-toast",
        });
        closeModal();
      } else {
        toast.error("Something went wrong.", {
          position: "top-center",
          className: 'custom-toast-error',
        });
      }
    } catch (error) {
      console.error("Error updating NFT details:", error);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value === "true"; 
    setIsAdminNftApproved(value);
    if (value === true) {
      setDescription('');  // Clear description if approved is true
      setDescriptionError(''); // Clear any previous error
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setDescriptionError('');  // Clear the error when user starts typing
  };

  const handleReset = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        onRequestClose={CloseModal}
        contentLabel="Update NFT Modal"
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            overflowY: 'auto',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 1000,
          },
        }}
      >
        <section className="setting-popup">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-header-profile">
                <div className="user-profile-use">
                  <h6>Update NFT Status</h6>
                </div>
                <button type="button" className="btn-close" onClick={CloseModal}>
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </div>
            </div>
            <div className="modal-body">
              <div className="user-details-form">
                <form className="row g-3 p-2" onSubmit={handleSubmit}>
                  <div className="col-md-12">
                    <label htmlFor="nftApproval" className="form-label">
                      Approve NFT
                    </label>
                    <div id="nftApproval">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="nftApproval"
                          id="approveTrue"
                          value="true"
                          checked={isAdminNftApproved === true}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="approveTrue">
                          True
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="nftApproval"
                          id="approveFalse"
                          value="false"
                          checked={isAdminNftApproved === false}
                          onChange={handleChange}
                        />
                        <label className="form-check-label" htmlFor="approveFalse">
                          False
                        </label>
                      </div>
                    </div>
                  </div>

                  {isAdminNftApproved === false && (
                    <div className="col-md-12">
                      <label htmlFor="description" className="form-label">
                        Description
                      </label>
                      <textarea
                        id="description"
                        className="form-control"
                        value={description}
                        onChange={handleDescriptionChange}
                        rows="3"
                      ></textarea>
                      {descriptionError && (
                        <div className="text-danger mt-2">{descriptionError}</div>
                      )}
                    </div>
                  )}

                  <div className="mbtn-row">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Save changes
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(UpdateAvailableNft);
