const setJwt = token => {
    localStorage.setItem("access_token", token);
}

const getJwt = () => {
    const token = localStorage.getItem("access_token");
    if(token !== null && token !== undefined) {
        return token;
    } else {
        return null;
    }
}

const removeJWT = () => {
    localStorage.removeItem("access_token");
}

const isValidToken = () => {
    const token = getJwt();
    if(!token) {
        return false
    }
    return true;
}

export default {
    setJwt,
    getJwt,
    removeJWT,
    isValidToken
}