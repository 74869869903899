import React from 'react';

const Footer =()=>{
  return (
  <React.Fragment>  
  <footer id="footer" className="footer">
    <div className="copyright">
      ©{(new Date().getFullYear())} Copyright <strong><span>Digital Title</span></strong>. All Rights Reserved
    </div>
  </footer>
    </React.Fragment>
  )
}
export default  Footer
