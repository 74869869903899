import React, { useEffect, useState } from "react";
import { injectModels } from "../../redux/injectModels";
import ChangePassword from "../changePasswordFile/ChangePassword";
import EditProfile from "./EditProfile";
import { CircularProgress } from "@mui/material";

function Profile(props) {

  const [data, setData] = useState({});
  const [userName, setUserName] = useState();
  const [job, setJob] = useState();
  const [image, setImage]=useState("");
  const [ showOverViewProfile ,setShowOverViewProfile] =useState(true);
  const role = localStorage.getItem("userRole");


  
  useEffect(()=>{
    getProfile();
  },[])

  const getProfile =async()=>{
    props.application.setLoading(true);
        try {
          const res = await props.admin.getUserProfile();
          if (res.user) {
        
            setData(res && res.user);
            setUserName(res && res.user.username);
            setJob(res && res.user.job);
            setImage(res && res.user.profile_image);
            props.application.setLoading(false);
          } else {
            props.application.setLoading(false);
          }
        } catch (error) {
          props.application.setLoading(false);
        }
  }
 const  handleOverView = ()=>{
    setShowOverViewProfile(true)
    getProfile();
 }
  return (
    <>
    <main id="main" className="main">
    <div className="pagetitle mb-3">
      <h1>Profile Management</h1>
      <nav>
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item"><a href="user-profile.html">Home</a></li>
          <li className="breadcrumb-item active">Profile Management</li>
        </ol>
      </nav>
    </div>
    <section className="section profile">
        <div className="row">
                {showOverViewProfile ? 
                    <div className="col-xl-4">    
                                <div className="card">
                                    <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">    
                                    {image ? (
                                      <img src={"image"} alt="Profile" className="rounded-circle" />
                                    ) : (
                                      <CircularProgress />
                                    )}
                                        <h2>{"userName"}</h2>
                                        <h3>{"job"}</h3>                        
                                    </div>
                                </div>
                            </div>
                    
                    
                    :""}            
            <div className="col-xl-8">
                <div className="card">
                    <div className="card-body pt-3">                        
                        <ul className="nav nav-tabs nav-tabs-bordered">    

                            <li className="nav-item">
                                <button className="nav-link active" data-bs-toggle="tab"
                                    data-bs-target="#profile-overview" onClick={handleOverView} >Overview</button>
                            </li>    
                            
                            {role === "admin"  &&  <li className="nav-item">
                            <button className="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" onClick={()=>{ setShowOverViewProfile(false)}}>Edit Profile</button>
                        </li> }
                           

                            <li className="nav-item">
                                <button className="nav-link" data-bs-toggle="tab"
                                    data-bs-target="#profile-change-password"  onClick={()=>{ setShowOverViewProfile(false)} }>Change Password</button>
                            </li>    
                        </ul>
                        <div className="tab-content pt-2">    
                            <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                <h5 className="card-title">About</h5>
                                <p className="fst-italic">{"Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type .."}</p>
    
                                <h5 className="card-title">Profile Details</h5>
    
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 label ">Full Name</div>
                                    <div className="col-lg-9 col-md-8">{"Demo User"}</div>
                                </div>
    
                               {
                                // <div className="row">
                                //     <div className="col-lg-3 col-md-4 label">Company</div>
                                //     <div className="col-lg-9 col-md-8">Lueilwitz, Wisoky and Leuschke</div>
                                // </div>
                            }
    
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Job</div>
                                    <div className="col-lg-9 col-md-8">{"Demo"}</div>
                                </div>
    
                               
                                 <div className="row">
                                     <div className="col-lg-3 col-md-4 label">Email Id</div>
                                     <div className="col-lg-9 col-md-8">{"demo@mailinator.com"}</div>
                                 </div>
    
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Address</div>
                                    <div className="col-lg-9 col-md-8">{"jaipur"}</div>
                                </div>
    
                                <div className="row">
                                    <div className="col-lg-3 col-md-4 label">Phone</div>
                                    <div className="col-lg-9 col-md-8">{"9874563210"}</div>
                                </div>
    
                            </div>
                            <EditProfile   />
                        <ChangePassword/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </main>
  </>
    
  );
}
export default injectModels(["admin", "application"])(Profile);
