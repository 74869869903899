import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { injectModels } from "../../redux/injectModels";

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

function ChangePassword(props) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldError, setOldError] = useState("");
  const [newError, setNewError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [error, setError] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [oldPasswordType, setOldPasswordType] = useState("password");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);

  const toggleOldPassword = (e) => {
    setShowOldPassword((prevShowPassword) => !prevShowPassword);
  };

  const togglePassword = (e) => {
    setShowNewPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleConfirmPassword = (e) => {
    setShowRePassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    // handleShow();
  }, []);

  const handleOldPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setOldError("Current Password is required!");
    } else {
      setOldError("");
    }
    setOldPassword(val);
  };

  // const handleNewPasswordChange = (e) => {
  //   e.preventDefault();
  //   const val = e.target.value;
  //   if (val === "") {
  //     setNewError("New Password is required");
  //   } else if (!passwordRegex.test(newPassword)) {
  //     setNewError(
  //       "New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol and minimum length is 8!"
  //     );
  //   } else {
  //     setNewError("");
  //   }
  //   setNewPassword(val);
  // };

  const handleNewPasswordChange = (e) => {
  e.preventDefault();
  const val = e.target.value; // Get the current value
  if (val === "") {
    setNewError("New Password is required");
  } else if (!passwordRegex.test(val)) { // Validate the current value
    setNewError(
      "New Password must contain at least one uppercase letter, one lowercase letter, one number, one symbol, and minimum length is 8!"
    );
  } else {
    setNewError("");
  }
  setNewPassword(val); // Update the state
};


  const handleConfirmPasswordChange = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setConfirmError("Confirm Password is required!");
    } else {
      setConfirmError("");
    }
    setConfirmPassword(val);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let valid = true;

    if (oldPassword.trim() === "") {
      setOldError("Current Password is required!");
      valid = false;
      return;
    } else {
      setOldError("");
    }
    if (newPassword.trim() === "") {
      setNewError("New Password is required!");
      valid = false;
      return;
    } else {
      if (!passwordRegex.test(newPassword)) {
        setNewError(
          "New Password must contain at least one uppercase letter, one lowercase letter, one number,special characters and minimum length is 8!"
        );
        return;
      }
      setNewError("");
    }
    if (confirmPassword.trim() === "") {
      setConfirmError("Confirm Password is required!");
      valid = false;
      return;
    } else {
      setConfirmError("");
    }

    if (oldPassword.trim() === newPassword.trim()) {
      setError("New Password cannot be same as Current Password");
      return;
    }

    // Check if new password is at least 8 characters long
    if (newPassword.length <8) {
      setNewError("New Password must be at least 8 characters long!");
      valid = false;
      return;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      setConfirmError("New Password and Confirm Password did not match!");
      valid = false;
      return;
    }

    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");
      try {        
        props.application.setLoading(true);
        const data = {
          current_password: oldPassword,
          new_password: newPassword.trim(),
        };
        const response = await props.admin.changePassword(data);
        console.log("response",response)
        if (response.status === 200) {
          props.application.setLoading(false);
          toast.success(response.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          // toast.dismiss();
          setOldPassword("");
          setConfirmPassword("");
          setNewPassword("");
        } else {
          setError(response.message);
          props.application.setLoading(false);
        }
      } catch (err) {
        props.application.setLoading(false);
        setError("Please enter the correct Old Password");
      }
    }
  };

  return (
    <>
      <div className="tab-pane fade pt-3" id="profile-change-password">
        <form onSubmit={handleSubmit}>
          <div className="row mb-3">
            <label
              for="currentPassword"
              className="col-md-4 col-lg-3 col-form-label">
              Current Password
            </label>
            <div className="col-md-8 col-lg-9">
            <div className="change">
              <input
                name="password"
                className="form-control"
                id="currentPassword"
                type={showOldPassword ? "text" : "password"}
                b
                onChange={handleOldPasswordChange}
                value={oldPassword}
              />

              <div className="input-group-btn">
                <span
                  className="btn btn-outline-primary custom-button-colour"
                  onClick={toggleOldPassword}
                >
                  {showOldPassword ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </span>
              </div>
              </div>
            </div>
          </div>
          {oldError && <div className="error">{oldError}</div>}
          <div className="row mb-3">
            <label
              for="newPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              New Password
            </label>
            <div className="col-md-8 col-lg-9">
            <div className="change">
              <input
                name="newpassword"
                className="form-control"
                id="newPassword"
                type={showNewPassword ? "text" : "password"}
                onChange={handleNewPasswordChange}
                value={newPassword}
              />
              <div className="input-group-btn">
                <span
                  className="btn btn-outline-primary custom-button-colour"
                  onClick={togglePassword}
                >
                  {showNewPassword ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </span>
              </div>
              </div>
            </div>
          </div>
          {newError && <div className="error">{newError}</div>}
          <div className="row mb-3">
            <label
              for="renewPassword"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Confirm Password
            </label>
            <div className="col-md-8 col-lg-9">
            <div className="change">
              <input
                name="renewpassword"
                className="form-control"
                id="renewPassword"
                type={showRePassword ? "text" : "password"}
                onChange={handleConfirmPasswordChange}
                value={confirmPassword}
              />
              <div className="input-group-btn">
                <span
                  className="btn btn-outline-primary custom-button-colour"
                  onClick={toggleConfirmPassword}
                >
                  {showRePassword ? (
                    <i className="fa-solid fa-eye"></i>
                  ) : (
                    <i className="fa-solid fa-eye-slash"></i>
                  )}
                </span>
              </div>
              </div>
            </div>
          </div>
          {confirmError && <div className="error">{confirmError}</div>}
          <div className="d-flex align-items-center justify-content-end">
            <button type="submit" className="btn btn-primary" >
              Change Password
            </button>
          </div>
        </form>
        {error && (
          <div className="col-xs-12 col-md-12 col-lg-12 mt-1">
            <div className="alert alert-danger">{error}</div>
          </div>
        )}
      </div>
    </>
  );
}
export default injectModels(["admin", "application"])(ChangePassword);
