// import React, { useState } from "react";
// import Modal from 'react-modal';
// import { injectModels } from "../../redux/injectModels";
// import { toast } from "react-toastify";

// const UpdateContent = ({isOpen, closeModal,props,contentId, slug ,title,subTitle,description}) => {
// const [titleId,setTitleId]=useState(contentId);
// const [titles,setTitles]=useState(title);
// const [titleError,setTitleError]=useState("");
// const [slugs ,setSlugs] = useState(slug)
// const [subTitles,setSubTitles] = useState(subTitle)
// const [subTitlesError,setSubTitlesError] = useState("")
// const [descriptions,setDescriptions] = useState(description);
// const [descriptionsError,setDescriptionsError] = useState("");

//   const handleTitle = (e) => {
//     const val = e.target.value;
//     if (val === "") {
//       setTitleError("Title is required!");
//     } else {
//       setTitleError("");
//     }
//     setTitles(val.trimStart());
//   };

//     const handleSubTitle = (e) => {
//     const val = e.target.value;
//     if (val === "") {
//       setSubTitlesError("Sub Title is required!");
//     } else {
//       setSubTitlesError("");
//     }
//     setSubTitles(val.trimStart());
//   };

//   const handleDescription= (e) => {
//     const val = e.target.value;
//     if (val === "") {
//       setDescriptionsError("Description is required!");
//     } else {
//       setDescriptionsError("");
//     }
//     setDescriptions(val.trimStart());
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     let valid = true;
//     if (titles == "" || titles == null) {
//       valid = false;
//       setTitleError('Title is required');
//     } else {
//       setTitleError("");
//     }
    
//     if (subTitles == "" || subTitles == null) {
//       valid = false;
//       setSubTitlesError('Sub Title is required');
//     } else {
//       setSubTitlesError("");
//     }

//     if (descriptions == "" || descriptions == null) {
//       valid = false;
//       setDescriptionsError('Description is required');
//     } else {
//       setDescriptionsError("");
//     }

//     if (!valid) {
//       return;
//     }
//     else {
//       const data = {
//         id: titleId,
//         slug:slugs,
//         title:titles,
//         subTitle:subTitles,
//         description:descriptions
       
//       }
//       try {
//         const updatedResponse = await props.admin.updateContentManagement(data);    
            
//         if (updatedResponse && updatedResponse.data.success === true) {          
//           closeModal();
//           toast.success(`${updatedResponse?.data?.message}`, {
//             position: "top-center",
//             className: "custom-toast",
//           });
//         } else {
//           toast.error("Something went wrong.", {
//             position: "top-center",            
//           className: 'custom-toast-error',
//           });
//         }
//       } catch (error) {
//         console.log("error", error)
//         console.error("Error updating Content details:", error);
//       }
//     }
//   }
//   const handlReset = () => {
//     closeModal();   
//   }
  
//   const CloseModal = () => {
//     closeModal();
//   };
  
//   const stripHtml = (input) => {
//     return input.replace(/<\/?[^>]+(>|$)/g, "");
// };

//   return (
//     <React.Fragment>
//       <div>
//         <Modal
//           isOpen={isOpen}
//           onRequestClose={CloseModal}
//           contentLabel="Example Modal"
//           style={{
//             content: {
//               top: '50%',
//               left: '50%',
//               right: 'auto',
//               bottom: 'auto',
//               marginRight: '-50%',
//               transform: 'translate(-50%, -50%)',
//               overflowY: 'auto',

//             },
//             overlay: {
//               backgroundColor: 'rgba(0, 0, 0, 0.5)',
//               zIndex: 1000,
//             },
//           }}
//         >        
//           <section className="setting-popup">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <div className="modal-header-profile">
//                   <div className="user-profile-use">                   
//                     <h6>Update Content Details</h6>
//                   </div>
//                   <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
//                 </div>
//               </div>
//               <div className="modal-body">
//                 <div className="user-details-form">
//                 <form className="row g-3 p-2">
//               <div className="col-md-12">
//                 <label htmlFor="" className="form-label">
//                   Title
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"    
//                   value={stripHtml(titles)} 
//                   onChange={handleTitle}                           
//                 />  
//                  {titleError && (
//                   <div className="text-danger">{titleError}</div>
//                 )}  
                            
//               </div> 
//                <div className="col-md-12">
//                 <label htmlFor="" className="form-label">
//                    Sub Title
//                 </label>
//                 <input
//                   type="text"
//                   className="form-control"    
//                   value={stripHtml(subTitles)} 
//                   onChange={handleSubTitle}                           
//                 />  
//                  {subTitlesError && (
//                   <div className="text-danger">{subTitlesError}</div>
//                 )}  
                            
//               </div> 
//               <div className="col-md-12">
//                 <label htmlFor="" className="form-label">
//                   Description
//                 </label>
//                 <textarea
//                   type="text"
//                   className="form-control" 
//                   value={descriptions}
//                   onChange={handleDescription}
//                 />   
//                 {descriptionsError && (
//                 <div className="text-danger">{descriptionsError}</div>
//                 )}        
//               </div>     
//                 <div className="mbtn-row">
//                 <button
//                   type="button"
//                   className="btn btn-secondary"                  
//                   onClick={handlReset}
//                 >
//                   Reset
//                 </button>
//                 <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
//                   Save changes
//                 </button>
//               </div>
//             </form>
//                 </div>
//               </div>
//             </div>
//           </section>
//         </Modal>
//       </div>
//     </React.Fragment>
//   );
// };
// export default injectModels(["admin","application"])(UpdateContent);



import React, { useState } from "react";
import Modal from "react-modal";
import { injectModels } from "../../redux/injectModels";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const UpdateContent = ({ isOpen, closeModal, props, contentId, slug, title, subTitle, description }) => {
  const [titleId, setTitleId] = useState(contentId);
  const [titles, setTitles] = useState(title);
  const [titleError, setTitleError] = useState("");
  const [slugs, setSlugs] = useState(slug);
  const [subTitles, setSubTitles] = useState(subTitle);
  const [subTitlesError, setSubTitlesError] = useState("");
  const [descriptions, setDescriptions] = useState(description);
  const [descriptionsError, setDescriptionsError] = useState("");

  const handleTitle = (e) => {
    const val = e.target.value;
    if (val === "") {
      setTitleError("Title is required!");
    } else {
      setTitleError("");
    }
    setTitles(val.trimStart());
  };

  const handleSubTitle = (e) => {
    const val = e.target.value;
    if (val === "") {
      setSubTitlesError("Sub Title is required!");
    } else {
      setSubTitlesError("");
    }
    setSubTitles(val.trimStart());
  };

  const handleDescription = (value) => {
    setDescriptions(value);
    if (value === "" || value === "<p><br></p>") {
      setDescriptionsError("Description is required!");
    } else {
      setDescriptionsError("");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let valid = true;
    if (titles === "" || titles == null) {
      valid = false;
      setTitleError("Title is required");
    } else {
      setTitleError("");
    }

    if (subTitles === "" || subTitles == null) {
      valid = false;
      setSubTitlesError("Sub Title is required");
    } else {
      setSubTitlesError("");
    }

    if (descriptions === "" || descriptions === "<p><br></p>") {
      valid = false;
      setDescriptionsError("Description is required");
    } else {
      setDescriptionsError("");
    }

    if (!valid) {
      return;
    } else {
      const data = {
        id: titleId,
        slug: slugs,
        title: titles,
        subTitle: subTitles,
        description: descriptions,
      };
      try {
        const updatedResponse = await props.admin.updateContentManagement(data);

        if (updatedResponse && updatedResponse.data.success === true) {
          closeModal();
          toast.success(`${updatedResponse?.data?.message}`, {
            position: "top-center",
            className: "custom-toast",
          });
        } else {
          toast.error("Something went wrong.", {
            position: "top-center",
            className: "custom-toast-error",
          });
        }
      } catch (error) {
        console.log("error", error);
        console.error("Error updating Content details:", error);
      }
    }
  };

  const handlReset = () => {
    closeModal();
  };

  const CloseModal = () => {
    closeModal();
  };
  const stripHtml = (input) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
};
  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: "50%",
              left: "50%",
              right: "auto",
              bottom: "auto",
              marginRight: "-50%",
              transform: "translate(-50%, -50%)",
              overflowY: "auto",
            },
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">
                    <h6>Update Content Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}>
                    <i className="fa-solid fa-xmark"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
                  <form className="row g-3 p-2">
                    <div className="col-md-12">
                      <label htmlFor="" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={stripHtml(titles)}
                        onChange={handleTitle}
                      />
                      {titleError && <div className="text-danger">{titleError}</div>}
                    </div>
                    <div className="col-md-12">
                      <label htmlFor="" className="form-label">
                        Sub Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={stripHtml(subTitles)}
                        onChange={handleSubTitle}
                      />
                      {subTitlesError && <div className="text-danger">{subTitlesError}</div>}
                    </div>
                    <div className="col-md-12" >
                      <label htmlFor="" className="form-label">
                        Description
                      </label>
                      <ReactQuill
                      
                        theme="snow"
                        value={stripHtml(descriptions)}
                        onChange={handleDescription}
                      />
                      {descriptionsError && <div className="text-danger">{descriptionsError}</div>}
                    </div>
                    <div className="mbtn-row">
                      <button type="button" className="btn btn-secondary" onClick={handlReset}>
                        Reset
                      </button>
                      <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default injectModels(["admin", "application"])(UpdateContent);
