import store from "../../store";
import * as ACTIONS from "./action";

const obj = { 
  //faq
  createFaq: (data) => store.dispatch(ACTIONS.createFaq(data)),
  updateFaq: (data) => store.dispatch(ACTIONS.updateFaq(data)),
  getAllFaq: () => store.dispatch(ACTIONS.getAllFaq()),
  deleteFaq: (data) => store.dispatch(ACTIONS.deleteFaq(data)),
// notification

  createNotification: (data) => store.dispatch(ACTIONS.createNotification(data)),
  updateNotification: (data) => store.dispatch(ACTIONS.updateNotification(data)),
  getAllNotification: () => store.dispatch(ACTIONS.getAllNotification()),
  deleteNotification: (data) => store.dispatch(ACTIONS.deleteNotification(data)),
  
// Content management

  updateContentManagement: (data) => store.dispatch(ACTIONS.updateContentManagement(data)),
  getAllContentManagement: () => store.dispatch(ACTIONS.getAllContentManagement()),
  deleteContentManagement: (data) => store.dispatch(ACTIONS.deleteContentManagement(data)),
// Contact Us 
  getAllContactUs: () => store.dispatch(ACTIONS.getAllContactUs()),
  //Change password
  changePassword:(data) => store.dispatch(ACTIONS.changePassword(data)), 
  // user 
  createUser:(data) => store.dispatch(ACTIONS.createUser(data)), 
  getAllUser:() => store.dispatch(ACTIONS.getAllUser()), 
  updateUser:(data) => store.dispatch(ACTIONS.updateUser(data)), 

  // Government panel
  createGovernment: (data) => store.dispatch(ACTIONS.createGovernment(data)),
  getAllGovernmentList: () => store.dispatch(ACTIONS.getAllGovernmentList()),

  // available nft management

  getAllApprovedNft: () => store.dispatch(ACTIONS.getAllApprovedNft()),
  getAllUnApprovedNft: () => store.dispatch(ACTIONS.getAllUnApprovedNft()),
  updateAvailableNft: (endId, data) => store.dispatch(ACTIONS.updateAvailableNft(endId,data)),
  fetchSingleNftDetailsNft: (singleId) => store.dispatch(ACTIONS.fetchSingleNftDetailsNft(singleId)),

  //

  getAllVehicleList: () => store.dispatch(ACTIONS.getAllVehicleList()),

//shared 

  getAllSharedList: () => store.dispatch(ACTIONS.getAllSharedList()),


};
export default obj;
