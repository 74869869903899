import * as CONSTANTS from "./constants";
const initialState = {
  userData: {},
  loadFaqTableData:false,
  loadFaqTableDatas:false,
  loadNotificationTableData:false,
  loadNotificationTableDatas:false,
  loadContentTableData:false,
  loadUserTableData:false,
  updateUserTable:false,
  loadGovData :false,
  loadMangeAvl :false
};


const adminReducer = (state = initialState, action) => {
  switch (action.type) {  
  // faq   
    case CONSTANTS.CREATE_FAQ_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
        loadFaqTableDatas:true
      };
    }
     case CONSTANTS.REFRESH_FAQ_TABLE_DATAS: {
      return {
        ...state,
        loadFaqTableDatas:false
      };
    }
  
    
    case CONSTANTS.CREATE_FAQ_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
     case CONSTANTS.GET_ALL_FAQ_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_FAQ_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

      case CONSTANTS.UPDATE_FAQ_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        loadFaqTableData :true
      };
    }
     case CONSTANTS.REFRESH_FAQ_TABLE_DATA: {
      return {
        ...state,
        loadFaqTableData :false
      };
    }
    
    
    case CONSTANTS.UPDATE_FAQ_FAILED: {
      return {
        ...state,
        data: null,
      };
    }

    // notification 
    
    case CONSTANTS.CREATE_NOTIFICATION_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
        loadNotificationTableData :true
      };
    }
      case CONSTANTS.REFRESH_NOTIFICATION_TABLE_DATA: {
      return {
        ...state,
        loadNotificationTableData :false
      };
    }    

    case CONSTANTS.CREATE_NOTIFICATION_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
     case CONSTANTS.GET_ALL_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_NOTIFICATION_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

      case CONSTANTS.UPDATE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loadNotificationTableDatas :true
      };
    }
       case CONSTANTS.REFRESH_NOTIFICATION_TABLE_DATAS: {
      return {
        ...state,
        loadNotificationTableDatas : false
      };
    }
   
    case CONSTANTS.UPDATE_NOTIFICATION_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

    //Content management

     case CONSTANTS.GET_ALL_CONTENT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_CONTENT_MANAGEMENT_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

      case CONSTANTS.UPDATE_CONTENT_MANAGEMENT_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loadContentTableData :true
      };
    }
  case CONSTANTS.REFRESH_CONTENT_MANAGEMENT_TABLE_DATA: {
      return {
        ...state,
        loadContentTableData: false
      };
    }
    
    case CONSTANTS.UPDATE_CONTENT_MANAGEMENT_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
// Contact Us    
     case CONSTANTS.GET_ALL_CONTACT_US_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_CONTACT_US_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
    // Change  password
     case CONSTANTS.PASSWORD_UPDATE: {
      return {
        ...state,
        isUpdated: action.payload,
      };
    }
    case CONSTANTS.PASSWORD_FAILED: {
      return {
          ...state,
           access_token: null
          };
    }
    
    // user 
      case CONSTANTS.CREATE_USER_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
        loadUserTableData:true
      };
    }
     case CONSTANTS.LOAD_USER_TABLE: {
      return {
        ...state,
        loadUserTableData:false
      };
    }

    case CONSTANTS.CREATE_USER_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

    case CONSTANTS.GET_ALL_USER_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    
    case CONSTANTS.GET_ALL_USER_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
     case CONSTANTS.UPDATE_USER_SUCCESS: {
      return {
        ...state,
        data: action.payload,
        updateUserTable :true
      };
    }
     case CONSTANTS.REFRESH_USERS_TABLE_DATA: {
      return {
        ...state,
        updateUserTable :false
      };
    }    
    
    case CONSTANTS.UPDATE_USER_FAILED: {
      return {
        ...state,
        data: null,
      };
    }
    // government panel
     case CONSTANTS.CREATE_GOV_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
        loadGovData:true
      };
    }
     case CONSTANTS.REFRESH_GOV_TABLE_DATA: {
      return {
        ...state,
        loadGovData:false
      };
    }  
    
    case CONSTANTS.CREATE_GOV_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

   case CONSTANTS.GET_ALL_GOVERNMENT_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_GOVERNMENT_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

    // available nft details
   case CONSTANTS.GET_ALL_APPROVED_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_APPROVED_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

      case CONSTANTS.GET_ALL_UNAPPROVED_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_UNAPPROVED_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }

    case CONSTANTS.UPDATE_AVAIL_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
        loadMangeAvl :true
      };
    }
 case CONSTANTS.REFRESH_AVAIL_TABLE_DATA: {
      return {
        ...state,
        loadMangeAvl :false
      };
    }


    case CONSTANTS.UPDATE_AVAIL_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
    
    case CONSTANTS.GET_SINGLE_NFT_DETAILS_SUCCESS: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_SINGLE_NFT_DETAILS_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }


    case CONSTANTS.GET_ALL_VEHICLE_NFT_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_VEHICLE_NFT_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }


     case CONSTANTS.GET_ALL_SHARED_NFT_SUCCESSFULLY: {
      return {
        ...state,
        data: action.payload.data,
      };
    }
    case CONSTANTS.GET_ALL_SHARED_NFT_FAILED: {
      return {
        ...state,
        access_token: null,
      };
    }
    default:
      return initialState;
  }
};
export default adminReducer;
