import React from 'react'
import { useNavigate } from 'react-router-dom';

const Error = () => {

  const navigate = useNavigate();

  const handleRedirect = () =>{
    const access = localStorage.getItem("access_token");
    if(access){
      navigate('/dashboard')
      
    }else {
      navigate('/')
    }
  }

  return (
    <React.Fragment>
    <section className="error-page">
    <div className="container">
      <div  className=" card not-found">
        <h1>404 Page Not Found</h1>
        <p>Oops! The page you're looking for could not be found.</p>
        <p>Please check the URL or navigate back to the homepage.</p>
        <button class="btn btn-primary w-50"  onClick={handleRedirect}>Go to Homepage</button>
      </div>
      </div>
      </section>
    </React.Fragment>
  )
}

export default Error
