import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Login from "./Components/loginFiles/Login";
import ForgotPassword from "./Components/loginFiles/ForgotPassword";
import ResetPassword from "./Components/loginFiles/RestPassword";
import Dashboard from "./layout/Das";
import Profile from "./Components/adminProfile/Profile";
import EditProfile from "./Components/adminProfile/EditProfile";
import User from "./Components/userFile/User";
import Loader from "./Components/loader/LoaderFile";
import ChangePassword from "./Components/changePasswordFile/ChangePassword";
import PrivateRoutes from "./utils/PrivateRoutes";
import PublicRoutes from "./utils/PublicRoutes";
import Error from "./Components/errorFile/Error";
import { routes } from "./constants/routes";
import UpdateUser from "./Components/userFile/UpdateUser";
import ViewUser from "./Components/userFile/ViewUser";
import CreateUser from "./Components/userFile/CreateUser";
import ContentList from "./Components/ContentManagement/ContentList";
import UpdateContent from "./Components/ContentManagement/UpdateContent";
import ViewContent from "./Components/ContentManagement/ViewContent";
import FAQList from "./Components/FAQ/FAQList";
import UpdateFAQ from "./Components/FAQ/UpdateFAQ";
import ViewFAQ from "./Components/FAQ/ViewFAQ";
import InquiryList from "./Components/InquiryManagement/InquiryList";
import ViewInquiry from "./Components/InquiryManagement/ViewInquiry";
import ViewNotification from "./Components/Notifications/ViewNotification";
import NotificationList from "./Components/Notifications/NotificationList";
import GovernmentList from './Components/governmentManagement/GovernmentList';
import ViewGovernment from './Components/governmentManagement/ViewGovernment';
import AvailableNftList from "./Components/AvailableNftManagement/AvailableNftList";
import ViewAvailableNft from "./Components/AvailableNftManagement/ViewAvailableNft";
import VehicleNftList from "./Components/VehicleNftManagement/VehicleNftList";
import SharedNftList from "./Components/sharedNftManagement/SharedNftList";
import ViewSharedNft from "./Components/sharedNftManagement/ViewSharedNft";
import  ViewVehicleNft  from "./Components/VehicleNftManagement/ViewVehicleNft";

function App() {
  return (
    <BrowserRouter>
    <ToastContainer />
      <Loader />
        <Routes>
        <Route element={ <PublicRoutes/>}>
         <Route exact path={routes.DEFAULT} element={<Login />} />
         </Route>         
          <Route path={routes.FORGOT_PASSWORD} element={<ForgotPassword />} />
          <Route path={routes.RESET_PASSWORD} element={<ResetPassword />}/>
          <Route exact element={<PrivateRoutes />}>
            <Route path={routes.DASHBOARD} element={<Dashboard />} />
            <Route path={routes.PROFILE} element={<Profile />} />
            <Route path={routes.EDIT_PROFILE} element={<EditProfile />} />            
            <Route path={routes.USER} element={<User />} />
            <Route path={routes.USER_CREATE_USER} element={<CreateUser/>} />
            <Route path={routes.USER_UPDATE_USER} element={<UpdateUser />} />
            <Route path={routes.USER_VIEW_USER} element={<ViewUser />} />
            <Route path={routes.CHANGE_PASSWORD} element={<ChangePassword />} />
            <Route path={routes.CONTENT_MANAGEMENT_SYSTEM} element={<ContentList />} />
            <Route path={routes.UPDATE_CONTENT} element={<UpdateContent />} />
            <Route path={routes.VIEW_CONTENT} element={<ViewContent />} />
            <Route path={routes.FAQ_LIST} element={<FAQList />} />
            <Route path={routes.Update_FAQ} element={<UpdateFAQ />} />
            <Route path={routes.View_FAQ} element={<ViewFAQ />} />
            <Route path={routes.INQUIRY_LIST} element={<InquiryList />} />
            <Route path={routes.VIEW_INQUIRY} element={<ViewInquiry />} />
            <Route path={routes.NOTIFICATION_LIST} element={<NotificationList />} />
            <Route path={routes.VIEW_NOTIFICATION} element={<ViewNotification />} />
            <Route path={routes.GOVERNMENT_LIST} element={<GovernmentList />} />
            <Route path={routes.VIEW_GOVERNMENT_LIST} element={<ViewGovernment />} />
            <Route path={routes.AVAILABLE_NFT_LIST} element={<AvailableNftList />} />
            <Route path={routes.VIEW_AVAILABLE_NFT_LIST} element={<ViewAvailableNft />} />
            <Route path={routes.VEHICLE_NFT_MANAGE} element={<VehicleNftList/>} />            
            <Route path={routes.SHARED_NFT_LIST} element={<SharedNftList />} />
            <Route path={routes.VIEW_SHARED_NFT_LIST} element={<ViewSharedNft />} />       
            <Route path={routes.VIEW_VEHICLE_NFT_LIST} element={<ViewVehicleNft />} />       


                 
          </Route>
          <Route path={routes.ERROR} element={<Error />} />
        </Routes>    
    </BrowserRouter>
  );
}
export default App;
