import React, { useEffect, useState } from 'react';
import Create from './Create';
import ViewGovernment from './ViewGovernment';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { injectModels } from '../../redux/injectModels';

const GovernmentList = (props) => {
  const [openToneModall, setOpenToneModall] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedGovernment, setSelectedGovernment] = useState(null);

  useEffect(() => {
    getAllGovernmentAccounts();
  },[props.admin.loadGovData]);

  const getAllGovernmentAccounts = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllGovernmentList();
      if (res && res.success === true) {
        setFilteredData(res.accounts);
      }
    } catch (error) {
      console.error("Failed to fetch government accounts", error);
    } finally {
      props.application.setLoading(false);
    }
  };

  const openToonModall = (government) => {
    setSelectedGovernment(government);
    setOpenToneModall(true);
  };

  const ToneModalclose = () => {
    setOpenToneModall(false);
    setSelectedGovernment(null);
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>Government Management</h1>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={Routes.DASHBOARD}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Government Management</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <div className="data-se">
                  <div className="d-flex justify-content-between">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
                    <div>
                      <a
                        href="#"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#largeModal"
                      >
                        Create Government
                      </a>
                    </div>
                  </div>
                  <div className="tab-content pt-2" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Email</th>
                            <th>Role</th>                           
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            filteredData.map((government, index) => (
                              <tr key={government._id}>
                                <td>{index + 1}</td>
                                <td>{government.email}</td>
                                <td>{government.role || "N/A"}</td>                               
                                <td>
                                  <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => openToonModall(government)}
                                  >
                                    <i className="bi bi-eye-fill"></i>
                                  </button>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">No government accounts found.</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Create />
      {openToneModall && (
        <ViewGovernment
          isOpen={openToneModall}
          closeModal={ToneModalclose}
          government={selectedGovernment}
        />
      )}
    </main>
  );
};
export default injectModels(['admin', 'application'])(GovernmentList);

// import React,{useEffect, useState} from 'react';
// import Create from './Create';
// import ViewGovernment from './ViewGovernment';
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";
// import { injectModels } from '../../redux/injectModels';

//  const GovernmentList = (props)=> {
//     const [openToneModall, setOpenToneModall] = useState(false);   
//     const [filteredData, setFilteredData] = useState([]);

//   useEffect(()=>{
//    getAllGovernmentAccounts();
//   },[]);

//     const getAllGovernmentAccounts = async () => {
//         props.application.setLoading(true);
//         try {
//             const res = await props.admin.getAllGovernmentList();    
//             console.log("sd",res)        
//             if (res && res.success === true) {
//                 setFilteredData(res.accounts);
//             }
//             props.application.setLoading(false);
//         } catch (error) {
//             console.error("Failed to fetch User", error);
//             props.application.setLoading(false);
//         }
//     };

//     const openToonModall = () => {            
//       setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//         setOpenToneModall(false);
//     };



//   return (
//       <main id="main" className="main">
//       <div className="pagetitle mb-3">
//         <h1>Government Management</h1>
//         <nav>
//           <ol className="breadcrumb mb-0">
//             <li className="breadcrumb-item">
//              <Link to={Routes.DASHBOARD}>Home</Link>
//             </li>
//             <li className="breadcrumb-item active">Government Management</li>
//           </ol>
//         </nav>
//       </div>
//       <section className="section dashboard">
//         <div className="row">
//           <div className="col-12">
//             <div className="card recent-sales overflow-auto">
//               <div className="card-body p-3">
//                 <div className="data-se">
//                   <div className="d-flex justify-content-between">
//                     <ul
//                       className="nav nav-pills mb-3"
//                       id="pills-tab"
//                       role="tablist">                     
//                     </ul>
//                     <div>
//                       <a
//                         href="#"
//                         className="btn btn-primary"
//                         data-bs-toggle="modal"
//                         data-bs-target="#largeModal"
//                       >
//                         Create Government
//                       </a>
//                     </div>
//                   </div>
//                   <div className="tab-content pt-2" id="myTabContent">
//                     <div
//                       className="tab-pane fade show active"
//                       id="pills-home"
//                       role="tabpanel"
//                       aria-labelledby="home-tab"
//                     >
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th>S.No</th>
//                             <th>Email</th>
//                             <th>Status</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <th>1</th>
//                             <td>demouser@mailinator.com</td>
//                             <td>denied</td>
//                             <td> 
//                              <button 
//                               className="btn btn-success btn-sm"
//                               onClick={() =>
//                               openToonModall()}>
//                               <i className="bi bi-eye-fill"></i>
//                               </button>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="pills-profile"
//                       role="tabpanel"
//                       aria-labelledby="profile-tab"
//                     >
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th>Role</th>
//                             <th>Company</th>
//                             <th>Interview Round</th>
//                             <th>Interview Type</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <th>Role 1</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 2</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 3</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 4</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       <Create/>
//        {openToneModall && <ViewGovernment isOpen={openToneModall} closeModal={ToneModalclose} props={props}/>} 
//     </main>
//   )
// }
// export default injectModels(['admin','application']) (GovernmentList)
