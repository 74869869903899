import axios from "axios";
import * as CONSTANTS from "./constants";

// FAQ management section

export const createFaq = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;    
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/create-faqs`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );            
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.CREATE_FAQ_SUCCESSFULLY,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_FAQ_TABLE_DATAS, payload: null });
          },3000)
      return response.data;
    } else {
      dispatch({type:CONSTANTS.CREATE_FAQ_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllFaq = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/get-faqs`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_FAQ_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_FAQ_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateFaq = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/faqs/update-faqs`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.data.success === true ) {
      dispatch({type:CONSTANTS.UPDATE_FAQ_SUCCESS,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_FAQ_TABLE_DATA, payload: null });
          },3000)
      return response;
    }else {
      dispatch({type:CONSTANTS.UPDATE_FAQ_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteFaq= (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/faqs/remove-faqs`,data,      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );      
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.DELETE_FAQ_SUCCESS,payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.DELETE_FAQ_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

// notification manager 

export const createNotification = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/create-notification`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );        
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.CREATE_NOTIFICATION_SUCCESSFULLY,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_NOTIFICATION_TABLE_DATA, payload: null });
          },3000)
      return response.data;
    } else {
      dispatch({type:CONSTANTS.CREATE_NOTIFICATION_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllNotification = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/get-all-notification`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_NOTIFICATION_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_NOTIFICATION_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateNotification = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/notification/update-one`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.data.success === true ) {
      dispatch({type:CONSTANTS.UPDATE_NOTIFICATION_SUCCESS,payload:response.data})
        setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_NOTIFICATION_TABLE_DATAS, payload: null });
          },3000)
      return response;
    }else {
      dispatch({type:CONSTANTS.UPDATE_NOTIFICATION_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification= (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/notification/remove-one`,data,      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );      
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.DELETE_NOTIFICATION_SUCCESS,payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.DELETE_NOTIFICATION_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

// content  management system 

export const getAllContentManagement = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/page/get-all-pages`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_CONTENT_MANAGEMENT_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_CONTENT_MANAGEMENT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateContentManagement = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/page/update-content`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true ) {
      dispatch({type:CONSTANTS.UPDATE_CONTENT_MANAGEMENT_SUCCESS,payload:response.data})
         setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_CONTENT_MANAGEMENT_TABLE_DATA, payload: null });
          },3000)
      return response;
    }else {
      dispatch({type:CONSTANTS.UPDATE_CONTENT_MANAGEMENT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const deleteContentManagement= (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/notification/remove-one`,data,      
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );      
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.DELETE_CONTENT_MANAGEMENT_SUCCESS,payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.DELETE_CONTENT_MANAGEMENT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};
// contact us 

export const getAllContactUs = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/contact/get-all-emails`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_CONTACT_US_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_CONTACT_US_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

//change password 
export const changePassword = (data) => async (dispatch, getState) => {  
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/change-password`,
      data,
      {
        headers: { 
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${access_token}`,
      },}
    );
    if (response.status === 200 && response.data) {
      dispatch({ type: CONSTANTS.PASSWORD_UPDATE, payload: response.data });
      return response.data;
    } else {
      dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
      return { success: false };
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: CONSTANTS.PASSWORD_FAILED, payload: null });
    return Promise.reject(error);
  }
};

// user management

export const createUser = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;    
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/user/create-new-user`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.CREATE_USER_SUCCESSFULLY,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.LOAD_USER_TABLE, payload: null });
          },3000)
      return response.data;
    } else {
      dispatch({type:CONSTANTS.CREATE_USER_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllUser = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/user/get-all-users`,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_USER_SUCCESSFULLY, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_USER_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};


export const updateUser = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/udpate-user-proflie`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );
    if (response && response.data.success === true ) {
      dispatch({type:CONSTANTS.UPDATE_USER_SUCCESS,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_USERS_TABLE_DATA, payload: null });
          },3000)
      return response;
    }else {
      dispatch({type:CONSTANTS.UPDATE_USER_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

// government panel 
export const createGovernment = (data) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/create-goverment`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );                
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.CREATE_GOV_SUCCESSFULLY,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_GOV_TABLE_DATA, payload: null });
          },3000)
      return response.data;
    } else {
      dispatch({type:CONSTANTS.CREATE_GOV_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllGovernmentList = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/admin/government-accounts`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );        
    if (response && response.data.success === true) {
      dispatch({type:CONSTANTS.GET_ALL_GOVERNMENT_SUCCESSFULLY, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_GOVERNMENT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};


// available nft details 

export const getAllApprovedNft = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/approved-nfts`,
      {
        headers: {
           "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.status === 200) {
      dispatch({type:CONSTANTS.GET_ALL_APPROVED_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_APPROVED_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const getAllUnApprovedNft = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/government-rejected-nfts`,
      {
        headers: {
           "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.status === 200) {
      dispatch({type:CONSTANTS.GET_ALL_UNAPPROVED_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_UNAPPROVED_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateAvailableNft = (endId ,data ) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/update-status/${endId}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.status === 200 ) {
      dispatch({type:CONSTANTS.UPDATE_AVAIL_SUCCESS,payload:response.data})
       setTimeout(()=>{
            dispatch({ type: CONSTANTS.REFRESH_AVAIL_TABLE_DATA, payload: null });
          },3000)
      return response;
    }else {
      dispatch({type:CONSTANTS.UPDATE_AVAIL_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

export const fetchSingleNftDetailsNft = (singleId) => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/single-nft/${singleId}`,
      {
        headers: {
           "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );    
    if (response && response.status === 200) {
      dispatch({type:CONSTANTS.GET_SINGLE_NFT_DETAILS_SUCCESS, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_SINGLE_NFT_DETAILS_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};
// manage vehicle nft 

export const getAllVehicleList = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/nfts-to-approve`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );            
    if (response && response.status === 200) {
      dispatch({type:CONSTANTS.GET_ALL_VEHICLE_NFT_SUCCESSFULLY, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_VEHICLE_NFT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};

//shared 

export const getAllSharedList = () => async (dispatch, getState) => { 
  try {
    const access_token = getState().auth.access_token;
    const response = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/nft/approved-nfts`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      }
    );            
    if (response && response.status === 200) {
      dispatch({type:CONSTANTS.GET_ALL_SHARED_NFT_SUCCESSFULLY, payload:response.data})
      return response.data;
    }else {
      dispatch({type:CONSTANTS.GET_ALL_SHARED_NFT_FAILED,payload:null})
      return Promise.reject(new Error(response.message));
    }
  } catch (error) {
    console.log(error);
  }
};