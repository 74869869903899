// import React from 'react'

// function ViewInquiry () {
//     return (
//         <div>
//             <h1>ViewInquiry</h1>
//         </div>
//     )
// }

// export default ViewInquiry 

import React from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";

const ViewInquiry = ({isOpen, closeModal,name,email,contactNumber,Company,jobTitle,message}) => {  
  const CloseModal = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',

            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">                   
                    <h6>View Inquiry Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
             <table className="table table-striped">
                    <thead>                     
                    </thead>
                    <tbody>                      
                      <tr>
                        <td>Name</td>
                        <td>{name || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{email || "N/A"}</td>
                      </tr>
                        <tr>
                        <td>Contact Number</td>
                        <td>{contactNumber || "N/A"}</td>
                      </tr>
                        {/* <tr>
                        <td>Company</td>
                        <td>{Company || "N/A"}</td>
                      </tr> */}
                        {/* <tr>
                        <td>Job Title</td>
                        <td>{jobTitle || "N/A"}</td>
                      </tr> */}
                        <tr>
                        <td>Message</td>
                        <td>{message || "N/A"}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default injectModels(["admin","application"])(ViewInquiry);