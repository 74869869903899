import React from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";

const ViewUser = ({isOpen, closeModal,_id,firstName,lastName,email,isActive,dataOfjoining}) => {  
  const CloseModal = () => {
    closeModal();
  };

  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',

            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">                   
                    <h6>View User Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
             <table className="table table-striped">
                    <thead>
                      {/* <tr>
                        <th>Title</th>
                        <th>Description</th>
                      </tr> */}
                    </thead>
                    <tbody>                      
                      <tr>
                        <td>First Name</td>
                        <td>{firstName  || "N/A"}</td>
                      </tr>
                       <tr>
                        <td>Last Name</td>
                        <td>{lastName  || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>{email || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td>{isActive ===false ? "Disabled": "Enabled" || "N/A"}</td>
                      </tr>
                       <tr>
                        <td>Date of Joining</td>
                        <td>{dataOfjoining || "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default injectModels(["admin","application"])(ViewUser);