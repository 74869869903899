import React, { useEffect, useState } from 'react';
import { injectModels } from '../../redux/injectModels';
import UpdateContent from './UpdateContent';
import ViewContent from './ViewContent'
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";

const ContentList =(props)=> {
    const [openToneModal, setOpenToneModal] = useState(false);
    const [openToneModall, setOpenToneModall] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [title_id ,setTitle_Id] = useState("");
    const [title ,setTitle] = useState("");
    const [ slug , setSlug] = useState("")
    const [subTitle,setSubTitle] = useState("")
    const [description , setDescription] = useState("");

    useEffect(() => {
        getAllContentManagement();
    },[props.admin.loadContentTableData]);

    const openToonModal = (_id ,slug,title,subTitle ,description) => { 
      setTitle_Id(_id)
      setSlug(slug)
      setTitle(title)
      setSubTitle(subTitle)
      setDescription(description)
      setOpenToneModal(true);
    };
    const openToonModall = (_id , slug,title,subTitle ,description) => { 
      setTitle_Id(_id)
      setSlug(slug)
      setTitle(title)
      setSubTitle(subTitle)
      setDescription(description)       
      setOpenToneModall(true);
    };

    const ToneModalclose = () => {
        setOpenToneModal(false);
        setOpenToneModall(false);
    };

   const getAllContentManagement = async () => {
    try {
        props.application.setLoading(true); 
        const res = await props.admin.getAllContentManagement();        
        if (res?.success) { 
            setFilteredData(res.updatedPages || []);
        } else {
            console.warn("Unexpected response structure:", res);
        }
    } catch (error) {
        console.error("Failed to fetch FAQs:", error);
    } finally {
        props.application.setLoading(false); 
    }
};

  const stripHtml = (input) => {
    return input.replace(/<\/?[^>]+(>|$)/g, "");
};

    return (
        <main id="main" className="main">
            <div className="pagetitle mb-3">
                <h1>Content Management</h1>
                <nav>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                           <Link to={Routes.DASHBOARD}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Content Management</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="row">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body p-3">
                                <div className="data-se">
                                    <div className="d-flex justify-content-between">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
                                        
                                    </div>
                                    <div className="tab-content pt-2" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >                                          
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Sub Title</th>
                                                        {/* <th>Description</th> */}
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((content, index) => (
                                                        <tr key={content._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{stripHtml(content.title)}</td> 
                                                            <td>{stripHtml(content.subTitle)}</td> 
                                                            {/* <td>{stripHtml(content.description)}</td>  */}
                                                            <td>                      
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() =>
                                                                        openToonModall(content._id,content.slug ,content.title, content.subTitle ,content.description)
                                                                    }
                                                                >
                                                                    <i className="bi bi-eye-fill"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() =>
                                                                        openToonModal(content._id,content.slug , content.title, content.subTitle  ,content.description)
                                                                    }
                                                                >
                                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                                </button>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {openToneModal && <UpdateContent isOpen={openToneModal} closeModal={ToneModalclose} props={props}
              contentId={title_id}
              slug={slug}
              title={title}
              subTitle={subTitle}
              description={description}
            />} 
             {openToneModall && <ViewContent isOpen={openToneModall} closeModal={ToneModalclose} props={props}
              contentId={title_id}              
              title={title}
              subTitle={subTitle}
              description={description}
            />}                        
        </main>
    );
}
export default injectModels(["admin","application"])(ContentList);
