import React, { useState,useRef } from "react";
import { toast } from "react-toastify";
import {injectModels} from '../../redux/injectModels'
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const passwordRegex = /^(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{5,10}$/;

const CreateUser = (props) => {
  const buttonRef = useRef(null);
  const [name, setName] = useState("");
  const [lastName,setLastName] =useState("")
  const [lastNameError,setLastNameError] =useState("")
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");  

  const handleFirstName = (e) => {
    const val = e.target.value;
    if (val === "") {
      setNameError("First name is required!");
    } else {
      setNameError("");
    }
    setName(val.trim());
  };

 const handleLastName = (e) => {
    const val = e.target.value;
    if (val === "") {
      setLastNameError("Last name is required!");
    } else {
      setLastNameError("");
    }
    setLastName(val.trim());
  };

  const handleEmail = (e) => {
    const val = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (val === "") {
      setEmailError("Email is required!");
    } else if (!emailRegex.test(val)) {
      setEmailError("Invalid email format!");
    } else {
      setEmailError("");
    }
    setEmail(val.trim());
  };

  const handlePassword = (e) => {
  const val = e.target.value;
  if (val === "") {
    setPasswordError("Password is required!");
  } else if (!passwordRegex.test(val)) {
    setPasswordError(
      "Password must be 5-10 characters and include at least one special character!"
    );
  } else {
    setPasswordError("");
  }
  setPassword(val.trim());
};

  const handleSubmit = async(e) => {
    e.preventDefault();
    let error = true;
    if(name.trim() === "") {
      error = false;
      setNameError("Name is required!");
    } else {
      setNameError("");
    }
     if(lastName.trim() === "") {
      error = false;
      setLastNameError("Last name is required!");
    } else {
      setLastNameError("");
    }

    if (email.trim() === "") {
      setEmailError("Email is required");
      error = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter the correct Email!");
      error = false;
    } else {
      setEmailError("");
    }
     if (password.trim() === "") {
        error = false;
        setPasswordError("Password is required!");
      } else if (!passwordRegex.test(password)) {
        error = false;
        setPasswordError(
          "Password must be 5-10 characters and include at least one special character!"
        );
      } else {
        setPasswordError("");
      }

      if (!error) {
        setError("Please fill all fields!");
        return;
      } else {
        setError("");
    
        try {
          let data = {
            firstName:name,
            lastName:lastName,
            email:email,
            password:password,
          }

          props.application.setLoading(true);
          const res = await props.admin.createUser(data);             
          if (res.success === true) {
            props.application.setLoading(false);
            toast.success(res.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            handleResetForm();
            const button = buttonRef.current;
          if (button) {
            button.click();
          }  
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_CENTER,
            });
            props.application.setLoading(false);
          }
        } catch (error) {
          console.log(error);
          props.application.setLoading(false);
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    };    
    
    const handleResetForm =()=>{
      setName("");
      setLastName("")
      setEmail("");
      setPassword("");
    }   
    const handleForm  =()=>{
      setName("");
      setLastName("")
      setEmail("");
      setPassword("");
      setNameError("");
      setLastNameError("");
      setEmailError("");
      setPasswordError("");
    }



  return (
    <div className="modal fade" id="largeModal" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create User</h5>
            <button
              ref={buttonRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleForm}
            ></button>
          </div>
          <div className="modal-body">
            <form className="row g-3 p-2" onSubmit={handleSubmit}>
              <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={handleFirstName}
                />
                {nameError && (
                  <div className="text-danger">{nameError}</div>
                )}
              </div>

              <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  Last Name
                </label>

                <input
                  type="text"
                  className="form-control"
                  value={lastName}
                  onChange={handleLastName}
                />
                {lastNameError && (
                  <div className="text-danger">{lastNameError}</div>
                )}
              </div> 

              <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={handleEmail}
                />
                {emailError && <div className="text-danger">{emailError}</div>}
              </div>
                          
               <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  value={password}
                  onChange={handlePassword}
                />
                {passwordError && (
                  <div className="text-danger">{passwordError}</div>
                )}
              </div>            
              <div className="mbtn-row">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleForm}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default injectModels(["admin","application"])( CreateUser);
