import React, { useEffect, useState } from 'react';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { injectModels } from '../../redux/injectModels';
import UpdateAvailableNft from './UpdateAvailableNft';
import { useNavigate } from "react-router-dom";


const AvailableNftList = (props) => {
  const navigate = useNavigate();
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [openToneModal, setOpenToneModal] = useState(false);
  const [openToneModall, setOpenToneModall] = useState(false);
  const [approvedData, setApprovedData] = useState([]);
  const [unapprovedData, setUnapprovedData] = useState([]);
  const [activeTable, setActiveTable] = useState("approved");
  const [nftDetails,setNftDetails] = useState("")
  const [isGovernmentApprovedDetails,setIsGovernmentApprovedDetails] = useState("")

  useEffect(() => {
    getAllApprovedNftList();
    getAllUnApprovedNFTList();
  }, [props.admin.loadMangeAvl]);

  const getAllApprovedNftList = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllApprovedNft();
      if (res && res.data) {
        setApprovedData(res.data);
        setIsGovernmentApprovedDetails(res.data.isGovermentApproved)
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error("Failed to fetch approved NFTs", error);
      props.application.setLoading(false);
    }
  };

  const getAllUnApprovedNFTList = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllUnApprovedNft();      
      if (res && res.nfts) {
        setUnapprovedData(res.nfts);
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error("Failed to fetch unapproved NFTs", error);
      props.application.setLoading(false);
    }
  };

  const handleTableChange = (tableType) => {
    setActiveTable(tableType);
    if (tableType === "approved") {
      if (approvedData.length === 0) getAllApprovedNftList();
    } else {
      if (unapprovedData.length === 0) getAllUnApprovedNFTList();
    }
  };

  const openToonModall = () => {
    setOpenToneModall(true);
  };

  const ToneModalclose = () => {
    setOpenToneModall(false);
    setOpenToneModal(false)
  };
     const openToonModal = (nft) => {
      setNftDetails(nft)
      setOpenToneModal(true);
    };

     const handleButtonClick = (nft) => {
    setSelectedNFT(nft); // Save the selected NFT to state
    navigate("/view-available-nft", { state: { nft } }); // Navigate to another page, passing the NFT as state
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>Available NFT Management</h1>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={Routes.DASHBOARD}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Available Nft</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <div className="data-se">
                  <div className="d-flex justify-content-between mb-3">
                    <button
                      className={`btn btn-primary ${activeTable === "approved" ? "active" : ""}`}
                      onClick={() => handleTableChange("approved")}
                    >
                      Approved NFTs
                    </button>
                    <button
                      className={`btn btn-secondary ${activeTable === "unapproved" ? "active" : ""}`}
                      onClick={() => handleTableChange("unapproved")}
                    >
                      Rejected NFTs
                    </button>
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      {activeTable === "approved" ? (
                        <tr>
                          <th>S.No</th>
                          <th>Owner Name</th>
                          <th>Vehicle Name</th>
                          <th>Admin Approved</th>
                          <th>Government Approved</th>
                          <th>Action</th>
                        </tr>
                      ) : (
                        <tr>
                          <th>S.No</th>
                         <th>Owner Name</th>
                          <th>Vehicle Name</th>
                          <th>Admin Approved</th>
                          <th>Government Approved</th>
                          <th>Action</th>
                        </tr>
                      )}
                    </thead>
                    <tbody>
                      {activeTable === "approved" ? (
                        approvedData.length > 0 ? (
                          approvedData.map((nft, index) => (
                            <tr key={nft._id}>
                              <th>{index + 1}</th>
                                <td>{nft.ownerName}</td>
                              <td>{nft.vehicleName}</td>
                              <td>{(nft.admin.isAdminApproved) === true ? "True" :"False"}</td>
                              <td>{(nft.goverment.isGovermentApproved) === true ? "True" :"False" } </td>
                              <td>
                                <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => handleButtonClick(nft)}
                                  >
                                    <i className="bi bi-eye-fill"></i>
                                  </button>
                                {" "}
                                {isGovernmentApprovedDetails === false ?
                                   <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                    openToonModal(nft)}
                                    >
                                    <i className="fa-regular fa-pen-to-square"></i>
                                    </button>
                                  :  ""}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No approved NFTs available.
                            </td>
                          </tr>
                        )
                      ) : unapprovedData.length > 0 ? (
                        unapprovedData.map((nft, index) => (
                          <tr key={nft._id}>
                            <th>{index + 1}</th>
                            <td>{nft.ownerName}</td>                            
                              <td>{nft.vehicleName}</td>
                              <td>{(nft.admin.isAdminApproved) === true ? "True" :"False"}</td>
                              <td>{(nft.goverment.isGovermentApproved) === true ? "True" :"False" }</td>                              
                            <td>
                              <button
                                className="btn btn-success btn-sm"
                                onClick={() => handleButtonClick(nft)}
                              >
                                <i className="bi bi-eye-fill"></i>
                              </button>
                              {" "}
                              <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                    openToonModal(nft)}
                                    >
                                    <i className="fa-regular fa-pen-to-square"></i>
                                    </button>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No unapproved NFTs available.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
        {openToneModal && (
                <UpdateAvailableNft
                    isOpen={openToneModal}
                    closeModal={ToneModalclose}
                    props={props}
                    nftDetails={nftDetails}                   
                />
            )}
    </main>
  );
};
export default injectModels(['admin', 'application'])(AvailableNftList);