// import React from "react";
// import { Routes } from "../constants";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
// import { toast } from "react-toastify";
// import Swal from "sweetalert2";

// const SideBar = ()=> {
//   const navigate = useNavigate();
//   const { pathname } = useLocation();
//   const splitLocation = pathname.split("/");

//   const handleLogOut = () => {
//     Swal.fire({
//       title: "Confirmation",
//       text: "Are you sure you want to Logout?",
//       showCancelButton: true,
//       confirmButtonColor: "#009462",
//       cancelButtonColor: "#314a5f",
//       confirmButtonText: `Yes`,
//     }).then(async (result) => {
//       if (result.isConfirmed) {
//         try {
//           let email = localStorage.getItem("RememberMeEmail");
//           let password = localStorage.getItem("Password");
//           localStorage.clear();
//           if (email != null && password != null) {
//             localStorage.setItem("RememberMeEmail", email);
//             localStorage.setItem("Password", password);
//           }
//           navigate("/");
//           toast.dismiss();
//           toast.success("Logged out successfully!", {
//             position: toast.POSITION.TOP_CENTER,
//           });
//         } catch (err) {
//           console.log(err);
//           Swal.fire("Error", "Something went wrong!", "error");
//           return Promise.reject(err);
//         }
//       }
//     });
//   };

//   return (
//     <React.Fragment>
//       <aside id="sidebar" class="sidebar">
//         <ul class="sidebar-nav" id="sidebar-nav">
//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.DASHBOARD}>              
//               <i
//                 className={`fa-solid fa-gauge-high ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Dashboard</span>
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.USER}
//             >
//               <i
//                 className={`fa-solid fa-users ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>User Management</span>
//             </Link>
//           </li>
          
//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.GOVERNMENT_LIST}
//             >
//               <i
//                 className={`fa-brands fa-goodreads-g ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Government Management</span>
//             </Link>
//           </li>

//           <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.AVAILABLE_NFT_LIST}
//             >
//               <i
//                 className={`fa-solid fa-n ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Available NFT Management</span>
//             </Link>
//           </li>

//            <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.VEHICLE_NFT_MANAGE}
//             >
//               <i
//                 className={`fa-solid fa-v ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Vehicle NFT Management</span>
//             </Link>
//           </li>


//              <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.SHARED_NFT_LIST}
//             >
//               <i
//                 className={`fa-solid fa-share ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Government Shared NFT Management</span>
//             </Link>
//           </li>
          
          

//              <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.CONTENT_MANAGEMENT_SYSTEM}
//             >
//               <i
//                 className={`fa-solid fa-arrows-to-circle ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Content Management</span>
//             </Link>
//           </li>
//            <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.NOTIFICATION_LIST}
//             >
//               <i
//                 className={`fa-solid fa-bell ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Notification Management</span>
//             </Link>
//           </li>
//          <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.INQUIRY_LIST}
//             >
//               <i
//                 className={`fa-solid fa-i ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>Inquiry Management</span>
//             </Link>
//           </li>
//             <li className="nav-item">
//             <Link
//               className={`nav-link ${
//                 pathname === "/dashboard" ? "collapsed active" : ""
//               } `}
//               to={Routes.FAQ_LIST}
//             >
//               <i
//                 className={`fa-solid fa-person-circle-question ${
//                   pathname === "/dashboard" ? "collapsed active" : ""
//                 } `}
//               />
//               <span>FAQ Management</span>
//             </Link>
//           </li>
//           <li class="nav-item">
//             <Link class="nav-link collapsed" to="#" onClick={handleLogOut}>
//               <i class="bi bi-box-arrow-right"></i>
//               <span>Logout</span>
//             </Link>
//           </li>
//         </ul>
//       </aside>
//     </React.Fragment>
//   );
// }
// export default SideBar


import React from "react";
import { Routes } from "../constants";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const SideBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleLogOut = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to Logout?",
      showCancelButton: true,
      confirmButtonColor: "#009462",
      cancelButtonColor: "#314a5f",
      confirmButtonText: `Logout`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let email = localStorage.getItem("RememberMeEmail");
          let password = localStorage.getItem("Password");
          localStorage.clear();
          if (email && password) {
            localStorage.setItem("RememberMeEmail", email);
            localStorage.setItem("Password", password);
          }
          navigate("/");
          // toast.dismiss();
          toast.success("Logged out successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
        } catch (err) {
          console.error(err);
          Swal.fire("Error", "Something went wrong!", "error");
        }
      }
    });
  };

  return (
    <React.Fragment>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.DASHBOARD ? "active" : "collapsed"
              }`}
              to={Routes.DASHBOARD}
            >
              <i className="fa-solid fa-gauge-high" />
              <span>Dashboard</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.USER ? "active" : "collapsed"
              }`}
              to={Routes.USER}
            >
              <i className="fa-solid fa-users" />
              <span>User Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.GOVERNMENT_LIST ? "active" : "collapsed"
              }`}
              to={Routes.GOVERNMENT_LIST}
            >
              <i className="fa-brands fa-goodreads-g" />
              <span>Government Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.AVAILABLE_NFT_LIST ? "active" : "collapsed"
              }`}
              to={Routes.AVAILABLE_NFT_LIST}
            >
              <i className="fa-solid fa-n" />
              <span>Available NFT Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.VEHICLE_NFT_MANAGE ? "active" : "collapsed"
              }`}
              to={Routes.VEHICLE_NFT_MANAGE}
            >
              <i className="fa-solid fa-v" />
              <span>Vehicle NFT Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.SHARED_NFT_LIST ? "active" : "collapsed"
              }`}
              to={Routes.SHARED_NFT_LIST}
            >
              <i className="fa-solid fa-share" />
              <span>Government Shared NFT Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.CONTENT_MANAGEMENT_SYSTEM
                  ? "active"
                  : "collapsed"
              }`}
              to={Routes.CONTENT_MANAGEMENT_SYSTEM}
            >
              <i className="fa-solid fa-arrows-to-circle" />
              <span>Content Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.NOTIFICATION_LIST ? "active" : "collapsed"
              }`}
              to={Routes.NOTIFICATION_LIST}
            >
              <i className="fa-solid fa-bell" />
              <span>Notification Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.INQUIRY_LIST ? "active" : "collapsed"
              }`}
              to={Routes.INQUIRY_LIST}
            >
              <i className="fa-solid fa-i" />
              <span>Inquiry Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={`nav-link ${
                pathname === Routes.FAQ_LIST ? "active" : "collapsed"
              }`}
              to={Routes.FAQ_LIST}
            >
              <i className="fa-solid fa-person-circle-question" />
              <span>FAQ Management</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link collapsed" to="#" onClick={handleLogOut}>
              <i className="bi bi-box-arrow-right" />
              <span>Sign Out</span>
            </Link>
          </li>
        </ul>
      </aside>
    </React.Fragment>
  );
};

export default SideBar;
