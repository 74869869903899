import React ,{useState , useRef} from 'react'
import {injectModels} from "../../redux/injectModels";
import { toast } from "react-toastify";


const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

 const Create = (props) => {
  const buttonRef = useRef(null);
  const [email,setEmail] =useState("");
  const [emailError, setEmailError] = useState("");
  const [password ,setPassword] = useState('')
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState("");  

 const handleEmail = (e) => {
    const val = e.target.value;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (val === "") {
      setEmailError("Email is required!");
    } else if (!emailRegex.test(val)) {
      setEmailError("Invalid email format!");
    } else {
      setEmailError("");
    }
    setEmail(val);
  };

    const handlePassword = (e) => {
    const val = e.target.value;
    if (val === "") {
      setPasswordError("Password is required!");
    } else {
      setPasswordError("");
    }
    setPassword(val);
  };

   const handleSubmit = async(e) => {
    e.preventDefault();
    let error = true;
    
    if (email.trim() === "") {
      setEmailError("Email is required");
      error = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter the correct Email!");
      error = false;
    } else {
      setEmailError("");
    }

    if (password.trim() === "") {
      error = false;
      setPasswordError("Password is required!");
    } else {
      setPasswordError("");
    }

    if (!error) {
      setError("Please fill all fields!");
      return;
      } else {
      setError("");
       try {
        let data = {
            email:email,
            password:password            
        };        
        props.application.setLoading(true); 
        const res = await props.admin.createGovernment(data);
        if (res?.success === true) {
          props.application.setLoading(false);
          toast.dismiss();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_CENTER,
          });         
          handleForm();     
          handleReset();     
          const button = buttonRef.current;
          if (button) {
            button.click();
          }    
        } else {
          toast.dismiss();
          toast.error(res?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
      }
      }
    };    
    const handleForm  =()=>{
      setEmail("");
      setPassword("");
    }
    const handleReset = () => {
        setEmail("");
      setPassword("");
      setEmailError("");  
      setPasswordError("");    
      setError("");
  };

  return (
     <div className="modal fade" id="largeModal" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Government Credential</h5>
            <button
             ref={buttonRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close" 
              onClick={handleReset}            
            >
            </button>
          </div>

          <div className="modal-body">
            <form className="row g-3 p-2"  onSubmit={handleSubmit}>              
              <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"  
                    value={email}
                  onChange={handleEmail}               
                />    
                {emailError && <div className="text-danger">{emailError}</div>}           
              </div>

                <div className="col-md-6">
                <label htmlFor="" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"       
                  value={password}
                  onChange={handlePassword}          
                />           
                 {passwordError && (
                  <div className="text-danger">{passwordError}</div>
                )}    
              </div>
              <div className="mbtn-row">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectModels(['admin','application'])(Create)