
import React ,{useEffect, useState} from "react";
import UpdateUser from "./UpdateUser";
import ViewUser from './ViewUser';
import { injectModels } from "../../redux/injectModels";
import CreateUser from "./CreateUser";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";

const User = (props) => {
  const [openToneModal, setOpenToneModal] = useState(false);
  const [openToneModall, setOpenToneModall] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [_id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState("");
  const [dataOfjoining, setDataOfJoining] = useState("");

  useEffect(()=>{
   getAllUsers();
  },[props.admin.loadUserTableData , props.admin.updateUserTable]);

    const getAllUsers = async () => {
        props.application.setLoading(true);
        try {
            const res = await props.admin.getAllUser();            
            if (res && res.success === true) {
                setFilteredData(res.users);
            }
            props.application.setLoading(false);
        } catch (error) {
            console.error("Failed to fetch User", error);
            props.application.setLoading(false);
        }
    };
      const openToonModal = ( _id ,firstName,lastName,email,isActive,dataOfjoining) => { 
          setId(_id)
        setFirstName(firstName)
        setLastName(lastName)
        setEmail(email)
        setIsActive(isActive)
        setDataOfJoining(dataOfjoining)                 
        setOpenToneModal(true);
    };


    const openToonModall = ( _id ,firstName,lastName,email,isActive,dataOfjoining ) => {      
        setId(_id)
        setFirstName(firstName)
        setLastName(lastName)
        setEmail(email)
        setIsActive(isActive)
        setDataOfJoining(dataOfjoining)            
        setOpenToneModall(true);
    };

    const ToneModalclose = () => {
        setOpenToneModal(false);
        setOpenToneModall(false);
    };

    // const deleteUser= ()=>{
    // }

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>User Management</h1>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
             <Link to={Routes.DASHBOARD}>Home</Link>
            </li>
            <li className="breadcrumb-item active">User Management</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <div className="data-se">
                  <div className="d-flex justify-content-between">
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist">
                    </ul>
                    <div>
                      <a
                        href="#"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#largeModal"
                      >
                        Create User
                      </a>
                    </div>
                  </div>
                  <div className="tab-content pt-2" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>                        
                        <tbody>
                            {filteredData.length > 0 ? (
                              filteredData.map((user, index) => (
                                <tr key={user._id}>
                                  <th>{index + 1}</th>
                                  <td>{`${user.firstName} ${user.lastName}`}</td>
                                  <td>{user.email}</td>
                                  <td>{user.isActive ? "Active" : "Inactive"}</td>
                                  <td>
                                    {/* <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteUser(user._id)}
                                    >
                                      <i className="bi bi-trash-fill"></i>
                                    </button> */}
                                    &nbsp;
                                    <button
                                      className="btn btn-success btn-sm"
                                      onClick={() => openToonModall(user._id ,user.firstName,user.lastName,user.email,user.isActive,user.dataOfjoining)}
                                    >
                                      <i className="bi bi-eye-fill"></i>
                                    </button>
                                    &nbsp;
                                    <button
                                      className="btn btn-primary btn-sm"
                                      onClick={() => openToonModal(user._id ,user.firstName,user.lastName,user.email,user.isActive,user.dataOfjoining)}
                                    >
                                      <i className="fa-regular fa-pen-to-square"></i>
                                    </button>
                                    &nbsp;
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="5" className="text-center">
                                  No users found.
                                </td>
                              </tr>
                            )}
                          </tbody>  
                          </table>  
                      </div>                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CreateUser/>
      {openToneModal && <UpdateUser isOpen={openToneModal} closeModal={ToneModalclose} props={props}   
        _id={_id}
        firstName={firstName}
        lastName={lastName}
        email= {email}
        isActive={isActive}
        dataOfjoining={dataOfjoining}


      />} 
      {openToneModall && <ViewUser isOpen={openToneModall} closeModal={ToneModalclose} props={props}  
        _id={_id}
        firstName={firstName}
        lastName={lastName}
        email= {email}
        isActive={isActive}
        dataOfjoining={dataOfjoining}
      />} 
    </main>
  );
};
export default injectModels(["admin", 'application'])(User);