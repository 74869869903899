import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { routes } from "../../constants/routes";
import { toast } from "react-toastify";
import MyLoader from "../loader/LoaderFile";
import { injectModels } from "../../redux/injectModels";
import { coverImageTypes } from "../../constants";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import FormData from "form-data";

function EditProfile(props) {
  let navigate = useNavigate();
  const [loader, setLoader] = useState("");

  const [image, setImage] = useState(0);
  const [profileImage, setProfileImage] = useState("");

  const avatarFileRef = React.useRef();
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);

  const [username, setUsername] = useState("");
  const [about, setAbout] = useState("");
  const [job, setJob] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [usernameError, setUsernameError] = useState("");
  const [profileImageError, setProfileImageError] = useState("");
  const [aboutError, setAboutError] = useState("");
  const [jobError, setJobError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [macAddressError, setMacAddressError] = useState("");
  const [cityError, setCityError] = useState("");
  const [stateError, setStateError] = useState("");
  const [postalCodeError, setPostalCodeError] = useState("");

  const handleUserName = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setUsernameError("Full name is required!");
    } else {
      setUsernameError("");
    }
    setUsername(val);
  };
  const handleProfileImage = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
    if (!file) {
      setProfileImageError("Profile Image is required!");
    } else {
      setProfileImageError("");
    }
  };

  const handleAbout = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAboutError("About is required!");
    } else {
      setAboutError("");
    }
    setAbout(val);
  };

  const handleJob = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setJobError("Job is required!");
    } else {
      setJobError("");
    }
    setJob(val);
  };
  const handleAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setAddressError("address is required!");
    } else {
      setAddressError("");
    }
    setAddress(val);
  };

  const handleCountry = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setCountryError("Country is required!");
    } else {
      setCountryError("");
    }
    setCountry(val);
  };
  const handlePhoneNumber = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPhoneNumberError("Phone is required!");
    } else {
      setPhoneNumberError("");
    }
    setPhoneNumber(val);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setEmailError("Email is required!");
    } else {
      setEmailError("");
    }
    setEmail(val);
  };
  
  const handleMacAddress = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setMacAddressError("IP address is required!");
    } else {
      setMacAddressError("");
    }
    setMacAddress(val);
  };
  const handleCity = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setCityError("City is required!");
    } else {
      setCityError("");
    }
    setCity(val);
  };
  const handleState = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setStateError("State is required!");
    } else {
      setStateError("");
    }
    setState(val);
  };
  const handlePostalCode = (e) => {
    e.preventDefault();
    const val = e.target.value;
    if (val === "") {
      setPostalCodeError("Postal Code is required!");
    } else {
      setPostalCodeError("");
    }
    setPostalCode(val);
  };
  // async function handleImageChange(e) {
  //   const file = e.target.files[0];
  //   if (!file) {
  //     return;
  //   }
  //   if (!file.type.startsWith("image/")) {
  //     toast.error("Please select a valid image file", {
  //       position: toast.POSITION.TOP_CENTER,
  //     });
  //     return;
  //   }
  //   setImage(file);

  //   // const data = new FormData();
  //   // data.append("fileInput", file);
  //   // try {
  //   //   setIsUploading(true);
  //   //   const response = await props.admin.uploadAdminImage(data);
  //   //   if (response.status === 200) {
  //   //     setImage(response.data && response.data.file.url);
  //   //     setIsUploading(false);
  //   //     props.application.setLoading(false);
  //   //   } else {
  //   //     props.application.setLoading(false);
  //   //   }
  //   // } catch (error) {
  //   //   toast(error.toString());
  //   //   props.application.setLoading(false);
  //   // }
  // }

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getUserProfile();
      if (res.user) {
        const details = res && res.user;
        setUsername(details.username);
        setEmail(details.email);
        setPhoneNumber(details.phone_number);
        setAbout(details.about);
        setMacAddress(details.mac_address);
        setJob(details.job);
        setCity(details.city);
        setState(details.state);
        setCountry(details.country);
        setPostalCode(details.postal_code);
        setAddress(details.address);
        setProfileImage(details.profile_image);
        props.application.setLoading(false);
      } else {
        props.application.setLoading(false);
      }
    } catch (error) {
      props.application.setLoading(false);
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();

    let valid = true;

    if (username === "") {
      setUsernameError("Full name is required!");
      valid = false;
      return;
    } else {
      setUsernameError("");
    }
    if (profileImage === "") {
      valid = false;
      setProfileImageError("Profile Image is required!");
    } else {
      setProfileImageError("");
    }
    if (phoneNumber === "") {
      setPhoneNumberError("Phone is required!");
      valid = false;
      return;
    } else {
      setPhoneNumberError("");
    }
    if (about === "") {
      setAboutError("About is required!");
      valid = false;
      return;
    } else {
      setAboutError("");
    }
    if (job === "") {
      setJobError("Job is required!");
      valid = false;
      return;
    } else {
      setJobError("");
    }
    if (city === "") {
      setCityError("City is required!");
      valid = false;
      return;
    } else {
      setCityError("");
    }
    if (state === "") {
      setStateError("State is required!");
      valid = false;
      return;
    } else {
      setStateError("");
    }
    if (country === "") {
      setCountryError("Country is required!");
      valid = false;
      return;
    } else {
      setCountryError("");
    }
    if (postalCode === "") {
      setPostalCodeError("Postal code is required!");
      valid = false;
      return;
    } else {
      setPostalCodeError("");
    }
    if (address === "") {
      setAddressError("Address is required!");
      valid = false;
      return;
    } else {
      setAddressError("");
    }
    if (macAddress === "") {
      setMacAddressError("IP address is required!");
      valid = false;
      return;
    } else {
      setMacAddressError("");
    }

    if (!valid) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");

      try {
        
        props.application.setLoading(true);
        let data = new FormData();
        data.append("profile_image", profileImage);
        data.append("username", username);
        data.append("mac_address", macAddress);
        data.append("about", about);
        data.append("job", job);
        data.append("address", address);
        data.append("phone_number", phoneNumber);
        data.append("city", city);
        data.append("state", state);
        data.append("country", country);
        data.append("postal_code", postalCode);

        const res = await props.admin.updateAdminProfile(data);

        if (res.data.success === true) {
          props.application.setLoading(false);
          toast.dismiss();
          toast.success(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          getProfile();
          // setImageLoading(false);
          navigate("/profile");
        } else {
          toast.dismiss();
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Could not update details", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  };

  const handleChangeProfile = () => {
    getProfile();
  };
  return (
    <>
      <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
        <form onSubmit={updateProfile}>
          <div className="row mb-3">
            <label
              for="profileImage"
              className="col-md-4 col-lg-3 col-form-label"
            >
              Profile Image
            </label>
            <div className="col-md-8 col-lg-9">
              
              {profileImage ? (
                <img src={profileImage} alt="Profile"  />
              ) : (
                <CircularProgress />
              )}

              <div className="pt-2">
                <input
                  type="file"
                  id="profileImage"
                  accept="image/"
                  onChange={handleProfileImage}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <label for="fullName" className="col-md-4 col-lg-3 col-form-label">
              Full Name
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="fullName"
                type="text"
                className="form-control"
                id="fullName"
                value={username}
                onChange={handleUserName}
              />
            </div>
            {usernameError && <span className="error">{usernameError}</span>}
          </div>

          <div className="row mb-3">
            <label for="Email" className="col-md-4 col-lg-3 col-form-label">
              Email
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="email"
                type="email"
                className="form-control"
                id="Email"
                value={email}
                onChange={handleEmail}
              />
            </div>
            {emailError && <span className="error">{emailError}</span>}
          </div>

          <div className="row mb-3">
            <label for="Phone" className="col-md-4 col-lg-3 col-form-label">
              Phone
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="phone"
                type="text"
                className="form-control"
                id="Phone"
                value={phoneNumber}
                onChange={handlePhoneNumber}
              />
            </div>
            {phoneNumberError && (
              <span className="error">{phoneNumberError}</span>
            )}
          </div>

          <div className="row mb-3">
            <label for="about" className="col-md-4 col-lg-3 col-form-label">
              About
            </label>
            <div className="col-md-8 col-lg-9">
              <textarea
                name="about"
                className="form-control"
                id="about"
                value={about}
                onChange={handleAbout}
                style={{ height: "50px" }}
              >
              </textarea>
            </div>
            {aboutError && <span className="error">{aboutError}</span>}
          </div>

          {
            // <div className="row mb-3">
            //   <label for="Company" className="col-md-4 col-lg-3 col-form-label">
            //     Company
            //   </label>
            //   <div className="col-md-8 col-lg-9">
            //     <input
            //       name="company"
            //       type="text"
            //       className="form-control"
            //       id="Company"
            //       value={company}
            //       onChange={handleCompany}
            //     />
            //   </div>
            //   {companyError && <span className="error">{companyError}</span>}
            // </div>
          }

          <div className="row mb-3">
            <label
              for="IPAddress<"
              className="col-md-4 col-lg-3 col-form-label"
            >
              IP Address
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="ipaddress<"
                type="text"
                className="form-control"
                id="IPAddress<"
                value={macAddress}
                onChange={handleMacAddress}
              />
            </div>
            {macAddressError && (
              <span className="error">{macAddressError}</span>
            )}
          </div>

          <div className="row mb-3">
            <label for="Job" className="col-md-4 col-lg-3 col-form-label">
              Job
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="job"
                type="text"
                className="form-control"
                id="Job"
                value={job}
                onChange={handleJob}
              />
            </div>
            {jobError && <span className="error">{jobError}</span>}
          </div>

          <div className="row mb-3">
            <label for="City" className="col-md-4 col-lg-3 col-form-label">
              City
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="city"
                type="text"
                className="form-control"
                id="City"
                value={city}
                onChange={handleCity}
              />
            </div>
            {cityError && <span className="error">{cityError}</span>}
          </div>

          <div className="row mb-3">
            <label for="State" className="col-md-4 col-lg-3 col-form-label">
              State
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="state"
                type="text"
                className="form-control"
                id="State"
                value={state}
                onChange={handleState}
              />
            </div>
            {stateError && <span className="error">{stateError}</span>}
          </div>

          <div className="row mb-3">
            <label for="Country" className="col-md-4 col-lg-3 col-form-label">
              Country
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="country"
                type="text"
                className="form-control"
                id="Country"
                value={country}
                onChange={handleCountry}
              />
            </div>
            {countryError && <span className="error">{countryError}</span>}
          </div>

          <div className="row mb-3">
            <label for="Postal" className="col-md-4 col-lg-3 col-form-label">
              Postal Code
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="postal"
                type="text"
                className="form-control"
                id="Postal"
                value={postalCode}
                onChange={handlePostalCode}
              />
            </div>
            {postalCodeError && (
              <span className="error">{postalCodeError}</span>
            )}
          </div>

          <div className="row mb-3">
            <label for="Address" className="col-md-4 col-lg-3 col-form-label">
              Address
            </label>
            <div className="col-md-8 col-lg-9">
              <input
                name="address"
                type="text"
                className="form-control"
                id="Address"
                value={address}
                onChange={handleAddress}
              />
            </div>
            {addressError && <span className="error">{addressError}</span>}
          </div>
          {error && (
            <div className="col-xs-12 col-md-12 col-lg-12">
              <div className="alert alert-danger">{error}</div>
            </div>
          )}
          <div className="d-flex align-items-center justify-content-end">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleChangeProfile}
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default injectModels(["admin", "application"])(EditProfile);
