import React, { useEffect, useState } from 'react';
import CreateFAQ from './CreateFAQ';
import { injectModels } from '../../redux/injectModels';
import UpdateFAQ from './UpdateFAQ';
import ViewFAQ from './ViewFAQ';
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";




const FAQList = (props) => {
    const [openToneModal, setOpenToneModal] = useState(false);
    const [openToneModall, setOpenToneModall] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [title_id, setTitle_Id] = useState("");
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");

    useEffect(() => {
        getAllFaqList();
    }, [props.admin.loadFaqTableData, props.admin.loadFaqTableDatas]);

    const openToonModal = (_id, question, answer) => {
        setTitle_Id(_id);
        setTitle(question);
        setDescription(answer);
        setOpenToneModal(true);
    };

    const openToonModall = (_id, question, answer) => {
        setTitle_Id(_id);
        setTitle(question);
        setDescription(answer);
        setOpenToneModall(true);
    };

    const ToneModalclose = () => {
        setOpenToneModal(false);
        setOpenToneModall(false);
    };

    const getAllFaqList = async () => {
        props.application.setLoading(true);
        try {
            const res = await props.admin.getAllFaq();
            if (res && res.Faqs) {
                setFilteredData(res.Faqs);
            }
            props.application.setLoading(false);
        } catch (error) {
            console.error("Failed to fetch FAQs", error);
            props.application.setLoading(false);
        }
    };

    const deleteFaq = async (id) => {
        let data = { id: id };
        Swal.fire({
            title: "Confirmation",
            text: "Are you sure you want to delete?",
            showCancelButton: true,
            confirmButtonColor: "#009462",
            cancelButtonColor: "#314a5f",
            confirmButtonText: `Yes`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    props.application.setLoading(true);
                    const res = await props.admin.deleteFaq(data);
                    if (res.success === true) {
                        toast.dismiss();
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                        getAllFaqList();
                        props.application.setLoading(false);
                    } else {
                        props.application.setLoading(false);
                        toast.dismiss();
                        toast.success(res.message, {
                            position: toast.POSITION.TOP_CENTER,
                        });
                    }
                } catch (error) {
                    console.log(error);
                    props.application.setLoading(false);
                    toast.dismiss();
                    toast.success("Something went wrong!", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                }
            }
        });
    };

    const truncateText = (text, wordLimit) => {
        if (!text) return ""; // Handle cases where the text might be undefined or null
        const words = text.split(" ");
        return words.length > wordLimit
            ? words.slice(0, wordLimit).join(" ") + "..."
            : text;
    };

    const sanitizeHtml = (html) => DOMPurify.sanitize(html);   

    return (
        <main id="main" className="main">
            <div className="pagetitle mb-3">
                <h1>FAQ Management</h1>
                <nav>
                    <ol className="breadcrumb mb-0">
                        <li className="breadcrumb-item">
                            <Link to={Routes.DASHBOARD}>Home</Link>
                        </li>
                        <li className="breadcrumb-item active">FAQ Management</li>
                    </ol>
                </nav>
            </div>
            <section className="section dashboard">
                <div className="row">
                    <div className="col-12">
                        <div className="card recent-sales overflow-auto">
                            <div className="card-body p-3">
                                <div className="data-se">
                                    <div className="d-flex justify-content-between">
                                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
                                        <div>
                                            <a
                                                href="#"
                                                className="btn btn-primary"
                                                data-bs-toggle="modal"
                                                data-bs-target="#largeModal"
                                            >
                                                Create FAQ
                                            </a>
                                        </div>
                                    </div>
                                    <div className="tab-content pt-2" id="myTabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="pills-home"
                                            role="tabpanel"
                                            aria-labelledby="home-tab"
                                        >
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Title</th>
                                                        <th>Description</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredData.map((faq, index) => (
                                                        <tr key={faq._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{(truncateText(faq.question,10))}</td>
                                                            <td>
                                                                <div className="faq-answer"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: sanitizeHtml((truncateText((faq.answer), 15))
                                                                        ),
                                                                    }}
                                                                />
                                                                
                                                            </td>
                                                            <td>
                                                                <button
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={() => deleteFaq(faq._id)}
                                                                >
                                                                    <i className="bi bi-trash-fill"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-success btn-sm"
                                                                    onClick={() =>
                                                                        openToonModall(faq._id, faq.question, faq.answer)
                                                                    }
                                                                >
                                                                    <i className="bi bi-eye-fill"></i>
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() =>
                                                                        openToonModal(faq._id, faq.question, faq.answer)}
                                                                >
                                                                    <i className="fa-regular fa-pen-to-square"></i>
                                                                </button>
                                                                &nbsp;
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CreateFAQ />
            {openToneModal && (
                <UpdateFAQ
                    isOpen={openToneModal}
                    closeModal={ToneModalclose}
                    props={props}
                    faqId={title_id}
                    title={title}
                    description={description}
                />
            )}
            {openToneModall && (
                <ViewFAQ
                    isOpen={openToneModall}
                    closeModal={ToneModalclose}
                    props={props}
                    faqId={title_id}
                    title={title}
                    description={description}
                />
            )}
        </main>
    );
};

export default injectModels(["admin", "application"])(FAQList);
