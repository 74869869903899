import axios from 'axios';
import { API_BASE_URL } from "../../constants";
import { JWT } from '../shared';
import { API_ENDPOINTS } from './EndPoints';

export const Login = async (data) => {  
    try {
        const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_LOGIN}`;
        const response = await axios.post(url, data, { headers: {'Content-Type': 'application/json'} });
        if (response.data.success === true) {
            JWT.setJwt(response.data.access_token);
            return response.data;
        } else {
            return response.data;
        }
    } catch (err) {
        return Promise.reject(err);
    }
};

export const Register = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/user`, data, { headers: { "Content-Type": "application/json" } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject(new Error("Internal server error"));
        }
    }
    catch (err) {
        return Promise.reject(err)
    }
};

export const ForgotPassword = async (data) => {
    try {
        const url = `${API_BASE_URL}${API_ENDPOINTS.ADMIN_FORGOT_PASSWORD}`;
        const response = await axios.post(url, data, {
            headers: { "Content-Type": "application/json" },
        });            
        if (response?.data?.success) {
            return response.data;
        } else if(response?.data?.status === 404) {
            return response.data
        } else {
            return Promise.reject(new Error("Internal server error"));
        }
    } catch (err) {
        return Promise.reject(err);
    }
};

