import React, { useState ,useRef} from 'react'
import { toast } from "react-toastify";
import {injectModels }from '../../redux/injectModels';

const CreateNotification =(props)=> {
  const buttonRef = useRef(null);
  const [title,setTitle]=useState("");
  const [titleError,setTitleError]=useState("");
  const [description,setDescription] = useState('');
  const [descriptionError,setDescriptionError] = useState('');
  const [error, setError] = useState("");

  const handleTitle = (e) => {
    const val = e.target.value;
    if (val === "") {
      setTitleError("Title is required!");
    } else {
      setTitleError("");
    }
    setTitle(val);
  };

  const handleDescription= (e) => {
    const val = e.target.value;
    if (val === "") {
      setDescriptionError("Description is required!");
    } else {
      setDescriptionError("");
    }
    setDescription(val);
  };
  
 const handleReset = () => {
  setTitle("")
    setDescription("")
  setTitleError("");  
  setDescriptionError("");    
  setError("");
  };
  const handleFormRestSubmit =()=>{
    setTitle("")
    setDescription("")
  }

   const handleSubmit = async (e) => { 
    e.preventDefault();
    let error = true;

    if (title.trim() === "") {
      error = false;
      setTitleError("Title is required!");
    } else {
      setTitleError("");
    }

    if (description.trim() === "") {
      error = false;
      setDescriptionError("Description is required!");
    } else {
      setDescriptionError("");
    }
    if (!error) {
      setError("Please fill all fields!");
      return;
    } else {
      setError("");
      try {
        let data = {
            name: title,
            description:description            
        };        
        props.application.setLoading(true); 
        const res = await props.admin.createNotification(data);
        if (res?.success === true) {
          props.application.setLoading(false);
          toast.dismiss();
          toast.success(res?.message, {
            position: toast.POSITION.TOP_CENTER,
          });         
          handleReset();          
          handleFormRestSubmit();
          const button = buttonRef.current;
          if (button) {
            button.click();
          }    
        } else {
          toast.dismiss();
          toast.error(res?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          props.application.setLoading(false);
        }
      } catch (error) {
        console.log(error);
        props.application.setLoading(false);
        toast.dismiss();
        toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
          });
      }
    }
  };

   return (
      <div className="modal fade" id="largeModal" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create Notification</h5>
            <button
             ref={buttonRef}
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleReset}             
            ></button>
          </div>
          <div className="modal-body">
            <form className="row g-3 p-2" onSubmit={handleSubmit}>
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"     
                  value={title}
                  onChange={handleTitle}            
                />  
                 {titleError && (
                  <div className="text-danger">{titleError}</div>
                )}            
              </div> 
              <div className="col-md-12">
                <label htmlFor="" className="form-label">
                  Description
                </label>
                <textarea
                  type="text"
                  className="form-control" 
                  value={description}
                  onChange={handleDescription}                
                />   
                 {descriptionError && (
                  <div className="text-danger">{descriptionError}</div>
                )}           
              </div>     
                <div className="mbtn-row">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button type="submit" className="btn btn-primary">
                  Create Notification
                </button>
              </div>      
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default injectModels(["admin","application"])(CreateNotification)
