import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { routes } from "../constants/routes";
import { Routes } from "../constants";
import Swal from "sweetalert2";

const  Header=()=> {
  const navigate = useNavigate();

  const userFirstName = localStorage.getItem("UserFristName");
  const userLastName = localStorage.getItem("UserLastName");

  const handleLogOut = () => {
    Swal.fire({
      title: "Confirmation",
      text: "Are you sure you want to Logout?",
      showCancelButton: true,
      confirmButtonColor: "#009462",
      cancelButtonColor: "#314a5f",
      confirmButtonText: `Logout`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let email = localStorage.getItem("RememberMeEmail");
          let password = localStorage.getItem("Password");
          localStorage.clear();
          if (email != null && password != null) {
            localStorage.setItem("RememberMeEmail", email);
            localStorage.setItem("Password", password);
          }
          navigate("/");
          toast.success("Logged out successfully!", {
            position: toast.POSITION.TOP_CENTER,
          });
          // toast.dismiss();
        } catch (err) {
          console.log(err);
          Swal.fire("FAQ!", "Something went wrong!", "error");
          return Promise.reject(err);
        }
      }
    });
  };

  const handleToggle = () => {
    const list = document.querySelector("body").classList;
    if (list.contains("toggle-sidebar")) {
      list.remove("toggle-sidebar");
    } else {
      list.add("toggle-sidebar");
    }
  };
  const handleProfile = (e) => {};

  return (
    <React.Fragment>
      <header 
        id="header"
        className="header fixed-top d-flex align-items-center"
      >      
  <div class="d-flex align-items-center justify-content-between">
  <Link to={routes.DASHBOARD} class="logo d-flex align-items-center">
    <img src="/assets/img/f-logo.svg" alt=""/>        
  </Link>
  <i class="bi bi-list toggle-sidebar-btn" onClick={handleToggle}></i>
</div>
    <div class="welcometxt ps-3">
      <p>
        Welcome to Digital Title!
      </p>
    </div>
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <nav class="header-nav ms-auto">
                <ul class="d-flex align-items-center">
                  <li class="nav-item dropdown pe-3">
                    <a
                      class="nav-link nav-profile d-flex align-items-center pe-0"
                      href="#"
                      data-bs-toggle="dropdown"
                    >
                      <img
                        src="/assets/img/profile-img.jpg"
                        alt="Profile"
                        class="rounded-circle"
                      />
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                      <li class="dropdown-header">
                        <h6>Demo User</h6>                     
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li>
                        <Link
                          class="dropdown-item d-flex align-items-center"
                          to={Routes.PROFILE}
                          onClick={handleProfile}
                        >
                          <i class="bi bi-person"></i>
                          <span>My Profile</span>
                        </Link>
                      </li>
                      <li>
                        <hr class="dropdown-divider" />
                      </li>
                      <li>
                        <Link
                          className="dropdown-item d-flex align-items-center"
                          to="#"
                          onClick={handleLogOut}>
                          <i className="bi bi-box-arrow-right" />
                          <span>Sign Out</span>
                        </Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </li>
          </ul>
        </nav>
      </header>
    </React.Fragment>
  );
}
export default Header