import React from "react";
import Modal from 'react-modal';
import { injectModels } from "../../redux/injectModels";

const ViewFAQ = ({isOpen, closeModal,title,description}) => {  
  const CloseModal = () => {
    closeModal();
  };
  
  return (
    <React.Fragment>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={CloseModal}
          contentLabel="Example Modal"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',

            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              zIndex: 1000,
            },
          }}
        >
          <section className="setting-popup">
            <div className="modal-content">
              <div className="modal-header">
                <div className="modal-header-profile">
                  <div className="user-profile-use">                   
                    <h6>View FAQ Details</h6>
                  </div>
                  <button type="button" className="btn-close" onClick={CloseModal}> <i className="fa-solid fa-xmark"></i> </button>
                </div>
              </div>
              <div className="modal-body">
                <div className="user-details-form">
             <table className="table table-striped">
                    <thead>                     
                    </thead>
                    <tbody>                      
                      <tr>
                        <td>Title</td>
                        <td>{title || "N/A"}</td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                        <div
                          style={{
                              maxHeight: "200px",
                              overflowY: "auto",
                              padding: "10px",
                              border: "1px solid #ddd",
                              borderRadius: "4px",
                              backgroundColor: "#f9f9f9",
                            }}                        
                        >
                        {description || "N/A"}
                        </div>
                        
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </Modal>
      </div>
    </React.Fragment>
  );
};
export default injectModels(["admin","application"])(ViewFAQ);