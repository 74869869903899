// import React,{useState} from 'react'
// import ViewSharedNft from './ViewSharedNft'
// import { Routes } from "../../constants";
// import { Link } from "react-router-dom";

//  const SharedNftList =(props)=>{
//   const [openToneModall, setOpenToneModall] = useState(false);

//     const openToonModall = () => {            
//       setOpenToneModall(true);
//     };

//     const ToneModalclose = () => {
//       setOpenToneModall(false);
//     };

//   return (
//        <main id="main" className="main">
//       <div className="pagetitle mb-3">
//         <h1>Shared NFT Management</h1>
//         <nav>
//           <ol className="breadcrumb mb-0">
//             <li className="breadcrumb-item">
//                           <Link to={Routes.DASHBOARD}>Home</Link>

//             </li>
//             <li className="breadcrumb-item active">Shared Nft</li>
//           </ol>
//         </nav>
//       </div>
//       <section className="section dashboard">
//         <div className="row">
//           <div className="col-12">
//             <div className="card recent-sales overflow-auto">
//               <div className="card-body p-3">
//                 <div className="data-se">
//                   <div className="d-flex justify-content-between">
//                     <ul
//                       className="nav nav-pills mb-3"
//                       id="pills-tab"
//                       role="tablist">                     
//                     </ul>                   
//                   </div>
//                   <div className="tab-content pt-2" id="myTabContent">
//                     <div
//                       className="tab-pane fade show active"
//                       id="pills-home"
//                       role="tabpanel"
//                       aria-labelledby="home-tab"
//                     >
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th>S.No</th>
//                             <th>Name</th>
//                             <th>Model</th>
//                             <th>Status</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <th>1</th>
//                             <td>Car</td>
//                             <td>4541455</td>
//                             <td>Pending</td>
//                              <td>
//                               &nbsp;                            
//                                 {''}
//                                 <button
//                               className="btn btn-success btn-sm"
//                               onClick={() => openToonModall()}
//                             >
//                               <i className="bi bi-eye-fill"></i>
//                             </button>                            
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                     <div
//                       className="tab-pane fade"
//                       id="pills-profile"
//                       role="tabpanel"
//                       aria-labelledby="profile-tab"
//                     >
//                       <table className="table table-bordered">
//                         <thead>
//                           <tr>
//                             <th>Role</th>
//                             <th>Company</th>
//                             <th>Interview Round</th>
//                             <th>Interview Type</th>
//                             <th>Action</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           <tr>
//                             <th>Role 1</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 2</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 3</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                           <tr>
//                             <th>Role 4</th>
//                             <td>Lorem ipsum dolor</td>
//                             <td>4th</td>
//                             <td>Technical</td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//       {openToneModall && <ViewSharedNft isOpen={openToneModall} closeModal={ToneModalclose} props={props}/>} 
//     </main> 
//   )
// }
// export default SharedNftList


import React, { useState, useEffect } from 'react';
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { injectModels } from '../../redux/injectModels';
import { useNavigate } from "react-router-dom";

const SharedNftList = (props) => {
  const navigate = useNavigate();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [details, setDetails] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState(null);


  useEffect(() => {
    getAllSharedNftList();
  }, []);

  const getAllSharedNftList = async () => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.getAllSharedList();
      console.log("Fetched NFTs:", res);
      if (res && res.data) {
        setDetails(res.data); // Save the list of vehicle NFTs
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error("Failed to fetch vehicle NFTs", error);
      props.application.setLoading(false);
    }
  };

 
  
     const handleButtonClick = (vehicle) => {
    setSelectedNFT(vehicle); // Save the selected NFT to state
    navigate("/view-shared-nft-list", { state: { vehicle } }); // Navigate to another page, passing the NFT as state
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>Shared NFT Management</h1>
        <nav>
          <ol className="breadcrumb mb-0">
            <li className="breadcrumb-item">
              <Link to={Routes.DASHBOARD}>Home</Link>
            </li>
            <li className="breadcrumb-item active">Shared NFT</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <div className="data-se">
                  <div className="d-flex justify-content-between">
                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist"></ul>
                  </div>
                  <div className="tab-content pt-2" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="home-tab"
                    >
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>S.No</th>
                            <th>Owner Name</th>
                            <th>VehicleName</th>                            
                            <th>Vehicle Description</th>                            
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {details.map((vehicle, index) => (
                            <tr key={vehicle._id}>
                              <th>{index + 1}</th>
                              <td>{vehicle.ownerName}</td>
                              <td>{vehicle.vehicleName}</td> {/* Assuming _id as model here */}
                              <td>{vehicle.vehicleDescription}</td>
                              <td>
                              {vehicle.admin.isAdminApproved === true
                                ? 'Approved'
                                : vehicle.admin.isAdminApproved === false
                                ? 'Denied'
                                : 'Pending'}
                            </td>
                              <td>
                                <button
                                    className="btn btn-success btn-sm"
                                    onClick={() => handleButtonClick(vehicle)}
                                  >
                                    <i className="bi bi-eye-fill"></i>
                                  </button>                              
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>    
    </main>
  );
};
export default injectModels(['admin', 'application'])(SharedNftList);
