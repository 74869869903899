export const routes = {
    DEFAULT: "/",
    DASHBOARD: "/dashboard",
    PROFILE: "/profile",
    EDIT_PROFILE: "/edit-profile",
    USER_PROFILE: "/user/profile",
    USER_REPORT: "/user/profile/report",
    USER: "/user",
    USER_CREATE_USER: "/user/create-user",
    USER_UPDATE_USER: "/user/update-user",
    USER_VIEW_USER: "/user/view-user",
    CHANGE_PASSWORD: "/change-password",
    FORGOT_PASSWORD: "/auth/forgot-password",
    RESET_PASSWORD:"/auth/reset-password",
    CONTENT_MANAGEMENT_SYSTEM:'/content-management-list',
    UPDATE_CONTENT:"/update-content",
    VIEW_CONTENT:'/view-content',
    FAQ_LIST:'/faq-list',
    Update_FAQ:'/update-faq',
    View_FAQ:'/view-faq',
    INQUIRY_LIST:'/Inquiry-List',
    VIEW_INQUIRY:'/view-inquiry',
    NOTIFICATION_LIST:'/notification-list',
    VIEW_NOTIFICATION:'/view-notification',
    GOVERNMENT_LIST:'/government-list',
    VIEW_GOVERNMENT_LIST:'/government-view',
    AVAILABLE_NFT_LIST : '/available-nft-list',
    VIEW_AVAILABLE_NFT_LIST : '/view-available-nft',    
    VEHICLE_NFT_MANAGE:'/vehicle-nft-list',
    VIEW_VEHICLE_NFT_MANAGE:'/view-vehicle-nft-list',
    SHARED_NFT_LIST:'/shared-nft-list',
    VIEW_SHARED_NFT_LIST:'/view-shared-nft-list',
    VIEW_VEHICLE_NFT_LIST:'/view-vehicle-nft-list',

    ERROR: "/*",
    };