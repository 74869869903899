import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { injectModels } from "../../redux/injectModels";

const ViewVehicleNft = (props) => {
  const [singleId, setSingleId] = useState("");
  const [fetchSingleDetails, setFetchSingleDetails] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { vehicle } = location.state || {};

  useEffect(() => {
    if (vehicle && vehicle._id) {
      setSingleId(vehicle._id);
      fetchSingleNftList(vehicle._id);
    }
  }, [vehicle]);

  const fetchSingleNftList = async (singleId) => {
    props.application.setLoading(true);
    try {
      const res = await props.admin.fetchSingleNftDetailsNft(singleId);
      if (res && res.data) {        
        setFetchSingleDetails(res?.data);
      }
      props.application.setLoading(false);
    } catch (error) {
      console.error("Failed to fetch NFT details", error);
      props.application.setLoading(false);
    }
  };


  if (!fetchSingleDetails) return null;

  const handleBack =()=>{
    navigate('/vehicle-nft-list')
  }

  return (
    <main id="main" className="main">
      <div className="pagetitle mb-3">
        <h1>View Vehicle NFT Details</h1>       
      </div>
      <section className="section dashboard">
        <div className="row">
          <div className="col-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body p-3">
                <h5>Vehicle Image</h5>               
                <img
                  src={fetchSingleDetails.vehicleImage}
                  alt="Vehicle"
                  className="img-fluid mb-3"
                />
                <h5>Owner Image</h5>
                <img
                  src={fetchSingleDetails.ownerImage}
                  alt="Owner"
                  className="img-fluid mb-3"
                />
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <td>Vehicle Name</td>
                      <td>{fetchSingleDetails.vehicleName || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Vehicle Description</td>
                      <td>{fetchSingleDetails.vehicleDescription || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Owner Name</td>
                      <td>{fetchSingleDetails.ownerName || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Email ID</td>
                      <td>{fetchSingleDetails.email || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{fetchSingleDetails.status || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Owner Address</td>
                      <td>{fetchSingleDetails.ownerAddress || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Wallet Address</td>
                      <td>{fetchSingleDetails.walletAddress || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Admin Approved</td>
                      <td>{fetchSingleDetails.admin.isAdminApproved ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Government Approved</td>
                      <td>{fetchSingleDetails.goverment.isGovermentApproved ? "Yes" : "No"}</td>
                    </tr>
                    <tr>
                      <td>Created At</td>
                      <td>{new Date(fetchSingleDetails.createdAt).toLocaleString() || "N/A"}</td>
                    </tr>
                    <tr>
                      <td>Updated At</td>
                      <td>{new Date(fetchSingleDetails.updatedAt).toLocaleString() || "N/A"}</td>
                    </tr>
                  </tbody>
                </table>

                <h5>Vehicle Required Documents</h5>
                {fetchSingleDetails.vehicleRequiredDocuments.map((doc, index) => (
                  <div key={index} className="mb-3">
                    <p><strong>{doc.imageName}</strong></p>
                    <img src={doc.imageUrl} alt={doc.imageName} className="img-fluid mb-2" />
                    <p>Date Created: {new Date(doc.dateCreated).toLocaleString()}</p>
                  </div>
                ))}

                <h5>Owner Required Documents</h5>
                {fetchSingleDetails.ownerRequiredDocuments.map((doc, index) => (
                  <div key={index} className="mb-3">
                    <p><strong>{doc.imageName}</strong></p>
                    <img src={doc.imageUrl} alt={doc.imageName} className="img-fluid mb-2" />
                    <p>Date Created: {new Date(doc.dateCreated).toLocaleString()}</p>
                  </div>
                ))}
              </div>
              <div className="back-btn">
                <button type="button" onClick={handleBack}>Back</button>
            </div>
            </div>

          </div>
        </div>
      </section>
    </main>
  );
};

export default injectModels(["admin", "application"])(ViewVehicleNft);